import {createReducer} from "@reduxjs/toolkit"
import actions from './actions'
import MeasurementsService from "../../services/MeasurementsService"
import NavigationService from "../../services/NavigationService"

const measurementsService = new MeasurementsService()
const navigationService = new NavigationService()

const addOrRemoveFromStateSeriesIdsToLoad = (stateSeriesIdsToLoad, itemId, visible) => {
    const indexOfInIdsToLoad = stateSeriesIdsToLoad.indexOf(itemId)
    if (visible === true && indexOfInIdsToLoad === -1) {
        stateSeriesIdsToLoad.push(itemId)
    } else if (visible === false && indexOfInIdsToLoad >= 0) {
        stateSeriesIdsToLoad.splice(indexOfInIdsToLoad, 1)
    }
}

const updateStateNavigation = (stateNavigation, itemId, visible, parentUpdatedCheckboxValue = null) => {
    for (let i = 0; i < stateNavigation.length; ++i) {
        for (let j = 0; j < stateNavigation[i].children.length; ++j) {
            if (stateNavigation[i].children[j].type === 'series' && stateNavigation[i].children[j].id === itemId) {
                stateNavigation[i].children[j].visible = visible
                break
            }

            if (stateNavigation[i].children[j].type === 'sub') {
                let childWasUpdated = false
                for (let k = 0; k < stateNavigation[i].children[j].children.length; ++k) {
                    if (stateNavigation[i].children[j].children[k].id === itemId) {
                        stateNavigation[i].children[j].children[k].visible = visible
                        childWasUpdated = true
                        break
                    }
                }

                if (childWasUpdated) {
                    if ((parentUpdatedCheckboxValue === null)) {
                        stateNavigation[i].children[j].subItemsCheckboxValue = navigationService.getSubItemsCheckboxValue(stateNavigation[i].children[j].children)

                    } else {
                        stateNavigation[i].children[j].subItemsCheckboxValue = parentUpdatedCheckboxValue
                    }
                }
            }
        }
    }
}

const initialState = {
    measurementsFetched: false,
    seriesDataFetched: false,
    loading: true,
    error: '',
    navigation: [],
    series: {
        allIds: [],
        idsToLoad: []
    },
    seriesLoader: {
        isLoading: false,
        error: null
    },
    chartOptions: {
        yAxis: null,
        series: []
    }
}

export default createReducer(initialState, {
    [actions.reset]: (state) => {
        state.measurementsFetched = initialState.measurementsFetched
        state.seriesDataFetched = initialState.seriesDataFetched
        state.loading = initialState.loading
        state.error = initialState.error
        state.navigation = [...initialState.navigation]
        state.series = {...initialState.series}
        state.seriesLoader = {...initialState.seriesLoader}
        state.chartOptions = {...initialState.chartOptions}
    },
    [actions.fetchMeasurementsStart]: state => {
        state.loading = true
        state.measurementsFetched = false
    },
    [actions.fetchMeasurementsSuccess]: (state, action) => {
        state.loading = false
        state.measurementsFetched = true
        state.error = ''

        const {
            navigation,
            series,
            initialSeriesData,
            yAxis
        } = measurementsService.getMeasurementsDataNormalized(action.payload.measurementsData)
        state.navigation = navigation
        state.series = series
        state.chartOptions.yAxis = yAxis
        state.chartOptions.series = initialSeriesData

    },
    [actions.fetchMeasurementsFailure]: (state, action) => {
        state.loading = false
        state.measurementsFetched = false
        state.error = action.payload.error
    },
    [actions.fetchSeriesDataStart]: (state, action) => {
        state.seriesDataFetched = false
        state.loading = true
    },
    [actions.fetchSeriesDataSuccess]: (state, action) => {
        const updatedSeries = []
        for (let i = 0; i < state.series.allIds.length; ++i) {
            const id = state.series.allIds[i]
            const indexOfSeries = action.payload.idsOfSeriesFetched.indexOf(id)

            if (indexOfSeries >= 0) {
                updatedSeries.push(action.payload.series[indexOfSeries])
            } else {
                updatedSeries.push({
                    ...state.chartOptions.series[i],
                    visible: false
                })
            }
        }

        state.chartOptions.series = updatedSeries
        state.seriesDataFetched = true
        state.loading = false
    },
    [actions.fetchSeriesDataFailure]: (state, action) => {
        state.seriesDataFetched = true
        state.loading = false
        // TODO log action.payload.error
    },
    [actions.patchSeriesSuccess]: (state, action) => {
        const indexOf = state.series.allIds.indexOf(action.payload.id)

        if (indexOf >= 0) {
            state.chartOptions.series[indexOf].visible = action.payload.data.visible

            addOrRemoveFromStateSeriesIdsToLoad(state.series.idsToLoad, action.payload.id, action.payload.data.visible)
            updateStateNavigation(state.navigation, action.payload.id, action.payload.data.visible)
        }

        state.seriesDataFetched = false
    },
    [actions.updateSeriesLoader]: (state, action) => {
        state.seriesLoader = {
            ...state.seriesLoader,
            ...action.payload.seriesLoader
        }
    },
    [actions.setNavigationSectionAreSubItemsVisible]: (state, action) => {
        for (let i = 0; i < state.navigation.length; ++i) {
            if (state.navigation[i].id === action.payload.sectionId) {
                state.navigation[i].areSubItemsVisible = action.payload.areSubItemsVisible
                break
            }
        }
    },
    [actions.setNavigationSubcategoryAreSubItemsVisible]: (state, action) => {
        for (let i = 0; i < state.navigation.length; i++) {
            if (state.navigation[i].id === action.payload.sectionId) {
                for (let j = 0; j < state.navigation[i].children.length; j++) {
                    if (state.navigation[i].children[j].type === 'sub' && state.navigation[i].children[j].id === action.payload.subcategoryId) {
                        state.navigation[i].children[j].areSubItemsVisible = action.payload.areSubItemsVisible
                        break
                    }
                }
                break
            }
        }
    },
    [actions.setSubItemsCheckboxValue]: (state, action) => {
        for (let i = 0; i < action.payload.updatedChildren.length; i++) {
            const visible = action.payload.parentUpdatedCheckboxValue === 'checked'
            const indexOf = state.series.allIds.indexOf(action.payload.updatedChildren[i].id)
            state.chartOptions.series[indexOf].visible = visible

            addOrRemoveFromStateSeriesIdsToLoad(state.series.idsToLoad, action.payload.updatedChildren[i].id, visible)
            updateStateNavigation(state.navigation, action.payload.updatedChildren[i].id, visible, action.payload.parentUpdatedCheckboxValue)
        }
    }
})
