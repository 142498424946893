import React, {useEffect} from 'react'
import {Route, withRouter} from 'react-router-dom'
import {connect, useDispatch} from "react-redux"
import appActions from "../../store/app/actions"
import Main from "../../layouts/Main/Main"

const RouteWithLayout = props => {
    const dispatch = useDispatch()
    const {component: Component, ...rest} = props
    const history = props.history

    const showFooterText = props.showFooterText !== undefined ? props.showFooterText : true

    useEffect(() => {

        if (props.isProtected === true && props.authIsLoggedIn === false) {
            history.push('/login')
        }

        if (props.authIsLoggedIn === true && props.path === '/login') {
            history.push('/')
        }

        if (props.appLocation.current === null || (props.appLocation.last !== null && props.appLocation.current !== history.location)) {
            const lastLocation = props.appLocation.current === null ? history.location : props.appLocation.current
            dispatch(appActions.setLocation(lastLocation, history.location))

            // messwerte
            if (lastLocation.pathname === '/energie' && history.location.pathname === '/messwerte') {
                dispatch(appActions.updateDateMeasurements(props.appSettings.date.min, props.appSettings.date.max, true))
            }
        }
    })

    return (
        <Route
            {...rest}
            render={matchProps => (
                <Main useMinimalLayout={props.useMinimalLayout} isProtected={props.isProtected} showFooterText={showFooterText}>
                    <Component {...matchProps} />
                </Main>
            )}
        />
    )
}

const mapStateToProps = state => {
    return {
        authIsLoggedIn: state.auth.isLoggedIn,
        appLocation: state.app.location,
        appSettings: state.app.settings
    }
}

export default withRouter(connect(mapStateToProps)(RouteWithLayout))
