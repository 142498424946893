import Service from "./Service"
import ElectricityRatesService from "./Rates/ElectricityRatesService"
import GasRatesService from "./Rates/GasRatesService"
import PhotovoltaicRatesService from "./Rates/PhotovoltaicRatesService";
import DistrictHeatingRatesService from "./Rates/DistrictHeatingRatesService";

export default class RatesServiceFactory extends Service {

    getRateServiceByName = typeName => {
        switch (typeName.toLowerCase()) {
            case 'gas':
                return new GasRatesService()
            case 'photovoltaik':
            case 'photovoltaic':
                return new PhotovoltaicRatesService()
            case 'fernwärme':
            case 'districtHeating':
                return new DistrictHeatingRatesService()
            case 'strom':
            case 'electricity':
                return new ElectricityRatesService()
            default:
                return null
        }
    }
}
