import LocalStorageService from "./services/LocalStorageService";
import axios from 'axios'
import wrapper from 'axios-cache-plugin'

const localStorageService = new LocalStorageService()

let axiosInstance = axios.create({
    withCredentials: false
})

axiosInstance.defaults.baseURL = process.env.REACT_APP_API_BASE_URL


axiosInstance.interceptors.request.use(request => {
    const token = localStorageService.getAccessToken();
    if (token && token !== 'undefined') {
        request.headers['Authorization'] = 'Bearer ' + token;
    }
    request.headers['Content-Type'] = 'application/json'

    return request
}, error => {
    // console.log('axios error',error)
    return Promise.reject(error)
})

let http = wrapper(axiosInstance, {
    maxCacheSize: 70,
    ttl: 60000, // time to live. if you set this option the cached item will be auto deleted after ttl(ms).
    excludeHeaders: true
})

http.__addFilter(/api\/messdaten/)

export default http
