import React from "react"
import {useField} from "formik"
import TextField from "@material-ui/core/TextField"

const AppTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <TextField

            error={!!(meta.touched && meta.error)}
            helperText={
                meta.touched && meta.error ? meta.error : null
            }
            label={label}
            onChange={field.onChange}
            {...field}
            {...props}
        />
    );
};

export default AppTextField;
