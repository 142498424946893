import {configureStore} from '@reduxjs/toolkit'
import appReducer from './app/reducer'
import notificationReducer from './notification/reducer'
import energyReducer from './energy/reducer'
import ratesReducer from './rates/reducer'
import measurementsReducer from './measurements/reducer'
import dashboardReducer from './dashboard/reducer'
import authReducer from './auth/reducer'
import thunk from "redux-thunk";

export default configureStore({
    reducer: {
        energy: energyReducer,
        rates: ratesReducer,
        measurements: measurementsReducer,
        dashboard: dashboardReducer,
        auth: authReducer,
        app: appReducer,
        notification: notificationReducer
    },
    middleware: [thunk]
})
