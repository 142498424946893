import React, {useEffect, useRef} from "react"
import Highcharts from "highcharts/highstock"
import highchartsMore from "highcharts/highcharts-more.js"
import bullet from "highcharts/modules/bullet"
import highchartsSolidGauge from "highcharts/modules/solid-gauge"

highchartsMore(Highcharts)
bullet(Highcharts)
highchartsSolidGauge(Highcharts)

Highcharts.setOptions({
    credits: false,
    lang: {
        decimalPoint: ",",
        thousandsSep: ".",
        loading: "Daten werden geladen...",
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        weekdays: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        shortMonths: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        exportButtonTitle: "Exportieren",
        printButtonTitle: "Drucken",
        rangeSelectorFrom: "Von",
        rangeSelectorTo: "Bis",
        rangeSelectorZoom: "Zeitraum",
        downloadPNG: "Download als PNG-Bild",
        downloadJPEG: "Download als JPEG-Bild",
        downloadPDF: "Download als PDF-Dokument",
        downloadSVG: "Download als SVG-Bild",
        resetZoom: "Zoom zurücksetzen",
        resetZoomTitle: "Zoom zurücksetzen"
    },
    global: {
        useUTC: false
    }
})


const HighchartsReactFunctional = props => {
    let container = useRef(null)
    let chart = useRef(null)

    const createChart = () => {
        const constructorType = props.constructorType || "chart"

        if (!Highcharts) {
            console.warn("The \"highcharts\" property was not passed.")
        } else if (!Highcharts[constructorType]) {
            console.warn("The \"constructorType\" property is incorrect or some " +
                "required module is not imported.")
        } else if (!props.options) {
            console.warn("The \"options\" property was not passed.")
        } else {
            // Create a chart
            chart.current = Highcharts[constructorType](
                container.current,
                props.options,
                props.callback ? props.callback : undefined
            )
        }
    }

    useEffect(() => {
        return () => {
            if (chart.current) {
                chart.current.destroy()
                chart.current = null
            }
        }
    }, [])

    useEffect(() => {
        if (props.allowChartUpdate !== false && (!props.immutable && chart.current)) {
            chart.current.update(
                props.options,
                ...(props.updateArgs || [true, true])
            )
            chart.current.reflow()
        } else {
            createChart()
        }
    })

    return (
        React.createElement(
            "div",
            {...props.containerProps, ref: container}
        )
    )

}

export default HighchartsReactFunctional
