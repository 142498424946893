import React, {Fragment} from "react"
import ListItemText from "@material-ui/core/ListItemText"
import {List, ListItem} from "@material-ui/core"
import CustomRouterLink from "../CustomRouterLink"
import {makeStyles} from "@material-ui/styles"
import measurementActions from "../../../../../../store/measurements/actions"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import Checkbox from "@material-ui/core/Checkbox"
import theme from "../../../../../../theme"


const useStyles = makeStyles(theme => ({
    sectionTitle: {
        padding: '3px 8px',
        '& span': {
            fontWeight: 'bold'
        }
    },
    sectionTitleActive: {
        '& span': {
            color: theme.palette.primary.main
        }
    },
    item: {
        paddingTop: 0,
        paddingBottom: 0,
        '& span.MuiIconButton-root': {
            padding: 4
        },
        '& .MuiListItemText-root span': {
            fontSize: 12
        }
    },
    itemSeries: {
        paddingTop: 0,
        paddingBottom: 0,
        '& span.MuiIconButton-root': {
            padding: 4
        },
        '& .MuiListItemText-root span': {
            fontSize: 12
        }
    }

}))

let loadSeriesTimeout = null
let loadSeriesTimeoutDuration = 700

const MeasurementsNavigation = props => {
    const classes = useStyles()
    const globalClasses = theme.globalUseStyles()
    const nav = props.nav[0]
    const dispatch = props.dispatch

    return (
        <Fragment>
            {
                nav.items.length > 0 &&
                <ListItem
                    button
                    title={nav.title}
                    component={CustomRouterLink}
                    to="/messwerte"
                    className={classes.sectionTitle}
                    activeClassName={classes.sectionTitleActive}
                    disableGutters
                >
                    <ListItemText primary={nav.title}/>
                </ListItem>
            }
            {
                nav.items.map(mainCategory => {
                    return (
                        <Fragment key={mainCategory.title}>
                            <ListItem className={classes.item} button
                                      onClick={event => {
                                          dispatch(measurementActions.setNavigationSectionAreSubItemsVisible(mainCategory.id, !mainCategory.areSubItemsVisible))
                                      }}
                                      title={mainCategory.title}
                            >
                                <ListItemText primary={mainCategory.title}/>

                                {mainCategory.hasSubItemsToggle === false
                                    ? null
                                    : mainCategory.areSubItemsVisible
                                        ? <ExpandLess/>
                                        : <ExpandMore/>
                                }

                            </ListItem>
                            <Collapse
                                in={mainCategory.areSubItemsVisible}
                                timeout="auto" unmountOnExit
                            >
                                <List disablePadding>
                                    {!mainCategory.children ? null : mainCategory.children.map(child => {
                                        const key = (child.type === 'series') ? child.id : mainCategory.title + '-' + child.title

                                        return (child.type === 'series')
                                            ? <ListItemSeries key={key} series={child} dispatch={dispatch}/>
                                            : <Fragment key={key}>
                                                <ListItem className={classes.item} button
                                                          onClick={event => {
                                                              event.stopPropagation()
                                                              dispatch(measurementActions.setNavigationSubcategoryAreSubItemsVisible(mainCategory.id, child.id, !child.areSubItemsVisible))
                                                          }}
                                                          title={key}
                                                          style={{paddingLeft: 5}}
                                                >
                                                    <Checkbox
                                                        checked={child.subItemsCheckboxValue === 'checked'}
                                                        indeterminate={child.subItemsCheckboxValue === 'indeterminate'}
                                                        inputProps={{'aria-label': 'indeterminate checkbox'}}
                                                        onClick={event => {
                                                            event.stopPropagation()
                                                            if (loadSeriesTimeout !== null) {
                                                                clearTimeout(loadSeriesTimeout)
                                                            }

                                                            loadSeriesTimeout = setTimeout(() => {
                                                                dispatch(measurementActions.setSubItemsCheckboxValue(child.id, child.subItemsCheckboxValue, child.children))
                                                            }, loadSeriesTimeoutDuration)

                                                        }}
                                                        size="small"
                                                        className={globalClasses.checkboxFirstLevel}
                                                    />

                                                    <ListItemText primary={child.title}/>

                                                    {child.hasSubItemsToggle === false
                                                        ? null
                                                        : child.areSubItemsVisible
                                                            ? <ExpandLess/>
                                                            : <ExpandMore/>
                                                    }
                                                </ListItem>
                                                <Collapse in={child.areSubItemsVisible}
                                                          timeout="auto"
                                                          unmountOnExit
                                                >
                                                    <List disablePadding>
                                                        {!child.children ? null : child.children.map(series => {
                                                            return <ListItemSeries key={series.id} series={series}
                                                                                   dispatch={dispatch}
                                                                                   loadSeriesTimeout={loadSeriesTimeout}
                                                                                   loadSeriesTimeoutDuration={loadSeriesTimeoutDuration}/>
                                                        })}
                                                    </List>

                                                </Collapse>
                                            </Fragment>
                                    })}
                                </List>
                            </Collapse>
                        </Fragment>
                    )
                })
            }
        </Fragment>
    )

}



const ListItemSeries = props => {
    const {dispatch, series, ...rest} = props
    const classes = useStyles()
    const globalClasses = theme.globalUseStyles()

    return (
        <ListItem button
                  className={classes.itemSeries}
                  onClick={event => {
                      event.stopPropagation()
                      dispatch(measurementActions.patchSeries(props.series.id, {visible: !series.visible}))
                  }}
                  title={series.title}
                  {...rest}
        >
            <Checkbox
                edge="start"
                tabIndex={-1}
                checked={series.visible}
                size="small"
                className={globalClasses.checkboxFirstLevel}
            />
            <ListItemText primary={series.title}/>
        </ListItem>
    )
}

export default MeasurementsNavigation