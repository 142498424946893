import React, {forwardRef, Fragment} from 'react'
import {NavLink as RouterLink} from 'react-router-dom'
import {makeStyles} from '@material-ui/styles'
import {colors, List, ListItem} from '@material-ui/core'
import ListItemText from "@material-ui/core/ListItemText"
import Checkbox from "@material-ui/core/Checkbox"
import energyActions from '../../../../../store/energy/actions'
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Divider from "@material-ui/core/Divider"
import MeasurementsNavigation from "./MeasurementsNavigation/MeasurementsNavigation"
import CustomRouterLink from "./CustomRouterLink"
import theme from "../../../../../theme"

const useStyles = makeStyles(theme => ({
    sectionTitle: {
        padding: '3px 8px',
        '& span': {
            fontWeight: 'bold'
        }
    },
    sectionTitleActive: {
        '& span': {
            color: theme.palette.primary.main
        }
    },
    item: {
        paddingTop: 0,
        paddingBottom: 0,
        '& span.MuiIconButton-root': {
            padding: 4
        },
        '& .MuiListItemText-root span': {
            fontSize: 12
        }
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    buttonItem: {
        color: colors.blueGrey[800],
        padding: '5px 10px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}))

const SidebarNav = props => {
    const {navigationGroups, className, dispatch, ...rest} = props

    const classes = useStyles()
    const globalClasses = theme.globalUseStyles()

    const dashboardNavigation = navigationGroups.dashboardNavigation.map(section => {
            return (
                <Fragment key={section.title}>
                    <ListItem
                        button
                        title={section.title}
                        component={CustomRouterLink}
                        to="/dashboard"
                        className={classes.sectionTitle}
                        activeClassName={classes.sectionTitleActive}
                    >
                        <ListItemText primary={section.title}/>
                    </ListItem>
                </Fragment>
            )
        }
    )

    const energyNavigation = navigationGroups.energyNavigation.map(section => {
            if (section.items.length) {
                return (
                    <Fragment key={section.title}>
                        <ListItem
                            button
                            title={section.title}
                            component={CustomRouterLink}
                            to="/energie"
                            className={classes.sectionTitle}
                            activeClassName={classes.sectionTitleActive}
                        >
                            <ListItemText primary={section.title}/>
                        </ListItem>

                        {
                            section.items.map(page => {
                                return (
                                    <Fragment
                                        key={page.title}
                                    >
                                        <ListItem
                                            className={classes.item}
                                            disableGutters
                                            button
                                            onClick={() => dispatch(energyActions.setCategoryAreSubItemsVisible(page.id, !page.areSubItemsVisible))}
                                            title={page.title}
                                        >
                                            <Checkbox
                                                checked={page.subItemsCheckboxValue === 'checked'}
                                                indeterminate={page.subItemsCheckboxValue === 'indeterminate'}
                                                inputProps={{'aria-label': 'indeterminate checkbox'}}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    dispatch(energyActions.setSubItemsCheckboxValue(page.id, page.subItemsCheckboxValue, page.children))
                                                }}
                                                size="small"
                                                className={globalClasses.checkboxFirstLevel}
                                            />

                                            <ListItemText primary={page.title}/>

                                            {page.hasSubItemsToggle === true
                                                ? null
                                                : page.areSubItemsVisible
                                                    ? <ExpandLess/>
                                                    : <ExpandMore/>
                                            }
                                        </ListItem>
                                        <Collapse
                                            in={page.areSubItemsVisible}
                                            timeout="auto" key={page.title} unmountOnExit>
                                            <List disablePadding>
                                                {!page.children ? null : page.children.map(chart => {
                                                    return (
                                                        <ListItem button key={chart.title} className={classes.item}
                                                                  onClick={event => {
                                                                      dispatch(energyActions.setChartVisibility(chart.id, !chart.visible))
                                                                  }}
                                                                  title={chart.title}
                                                        >
                                                            <Checkbox
                                                                edge="start"
                                                                tabIndex={-1}
                                                                checked={chart.visible}
                                                                size="small"
                                                                className={globalClasses.checkboxSecondLevel}
                                                            />
                                                            <ListItemText primary={chart.title}/>
                                                        </ListItem>
                                                    )
                                                })
                                                }
                                            </List>
                                        </Collapse>
                                    </Fragment>

                                )
                            })

                        }
                    </Fragment>
                )
            }
            return null
        }
    )

    const ratesNavigation = navigationGroups.ratesNavigation.map(section => {
            if (section.items.length) {
                return (
                    <Fragment key={section.title}>
                        <ListItem
                            button
                            title={section.title}
                            className={classes.sectionTitle}
                            activeClassName={classes.sectionTitleActive}
                            component={CustomRouterLink}
                            to="/tarife"
                            onClick={e => e.preventDefault()}
                        >
                            <ListItemText primary={section.title}/>
                        </ListItem>
                        {
                            section.items.map(page => {
                                return (
                                    <ListItem
                                        button
                                        className={classes.item}
                                        key={page.title}
                                        component={CustomRouterLink}
                                        to={page.href}
                                        title={page.title}
                                    >
                                        <ListItemText primary={page.title}/>
                                    </ListItem>
                                )
                            })

                        }
                    </Fragment>
                )
            }
            return null
        }
    )

    const messkonzeptNavigation = navigationGroups.messkonzeptNavigation.map(section => {
            if (section) {
                return (
                    <Fragment key={section.title}>
                        <ListItem
                            button
                            title={section.title}
                            // component={CustomRouterLink}
                            // to={section.href}
                            className={classes.sectionTitle}
                            activeClassName={classes.sectionTitleActive}
                            onClick={e => {
                                e.preventDefault()
                                window.open(section.href, '_blank')
                            }}
                        >
                            <ListItemText primary={section.title}/>
                        </ListItem>
                    </Fragment>
                )
            }
            return null
        }
    )

    return (
        <div>
            <List
                {...rest}
                className={classes.root}
            >
                {dashboardNavigation}
                <Divider/>
                {energyNavigation}
                <Divider/>
                {ratesNavigation}
                <Divider/>
                <MeasurementsNavigation nav={navigationGroups.measurementsNavigation} dispatch={dispatch}/>
                <Divider/>
                {messkonzeptNavigation}
            </List>
        </div>
    )
}

export default SidebarNav
