import React from "react"
import {useField} from "formik"
import Switch from '@material-ui/core/Switch'

const AppSwitchField = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <Switch

            error={!!(meta.touched && meta.error)}
            helperText={
                meta.touched && meta.error ? meta.error : null
            }
            label={label}
            onChange={field.onChange}
            {...field}
            {...props}
        />
    )
}

export default AppSwitchField
