export default class Service {

    static instance

    constructor() {
        if (!this.instance) {
            this.instance = this
        }

        return this.instance
    }
}
