import Service from "./Service";

export default class LocalStorageService extends Service {

    setAccessToken = accessToken => {
        localStorage.setItem('token', accessToken);
    }

    getAccessToken = () => {
        return localStorage.getItem('token')
    }

    clearToken = () => {
        localStorage.removeItem('token')
    }

    clear = () => {
        localStorage.clear()
    }
}
