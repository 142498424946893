import React from "react"
import {Grid, Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles"
import teaserImage from "../assets/images/auth.jpg"

const useStyles = makeStyles(theme => ({
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#4451B5',
        backgroundImage: `url(${teaserImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    }
}))

const ImageGridItem = () => {
    const classes = useStyles()


    return (
        <Grid
            className={classes.quoteContainer}
            item
            lg={5}
        >
            <div className={classes.quote}>
                <div className={classes.quoteInner}>
                    <Typography
                        className={classes.quoteText}
                        variant="h1"
                    >
                        Energiemonitoring <br/>
                        mit Mehrwert
                    </Typography>
                    <div className={classes.person}>
                        <Typography
                            className={classes.name}
                            variant="body1"
                        >
                            Geeignet für Multi-Liegenschaft, Wohnungswirtschaft, Filialbetrieb oder
                            Einzelgebäude
                        </Typography>
                        <Typography
                            className={classes.bio}
                            variant="body2"
                        >
                            by emonit GmbH
                        </Typography>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default ImageGridItem
