import React from "react"
import {Grid, Typography} from "@material-ui/core"
import AppDateField from "../../Form/AppDateField"
import AppTextField from "../../Form/AppTextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import AppSwitchField from "../../Form/AppSwitchField"

const GasFormFields = props => {

    const {classes, values, onChangeFormField, formValues, setFormValues} = props

    return (
        <Grid container spacing={3}>
            <Grid container item spacing={3} className={classes.formSection}>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>
                        Basisangaben
                    </Typography>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item md={6} xs={12}>
                        <AppDateField label="Datum" name="date" type="date" fullWidth variant="outlined"
                                      onChange={(e) => onChangeFormField(e, formValues, setFormValues)}/>
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item md={6} xs={12}>
                        <AppTextField labelWidth={60} label="MwSt. %" name="vat" type="text" fullWidth
                                      variant="outlined"
                                      InputLabelProps={{shrink: true}}
                                      onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                      InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Zustandszahl" name="stateNumber"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Brennwert" name="heatingValue"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={3} className={classes.formSectionWithBg}>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>
                        Grundpreis
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Grundpreis pro Monat - Netto" name="basePriceMonthNet"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Grundpreis pro Monat - Brutto" name="basePriceMonthGross"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Grundpreis pro Jahr - Netto" name="basePriceYearNet" type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Grundpreis pro Jahr - Brutto" name="basePriceYearGross" type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{
                                      endAdornment: <InputAdornment position="end">€</InputAdornment>
                                  }}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={3} className={classes.formSection}>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>
                        Arbeitspreis
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item md={6} xs={12}>
                        <FormControlLabel
                            control={
                                <AppSwitchField
                                    checked={values['workPriceInputInEuro']}
                                    name="workPriceInputInEuro"
                                    onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                />
                            }
                            label={"Arbeitspreis in " + (values['workPriceInputInEuro'] ? "Euro/kWh" : "Cent/kWh")}
                        />
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Arbeitspreis - Netto" name="workPriceNet" type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">{values['workPriceInputInEuro'] ? "Euro/kWh" : "Cent/kWh"}</InputAdornment>}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Arbeitspreis - Brutto" name="workPriceGross" type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">{values['workPriceInputInEuro'] ? "Euro/kWh" : "Cent/kWh"}</InputAdornment>}}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={3} className={classes.formSectionWithBg}>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>
                        Monatsabschlag
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Monatsabschlag - Netto" name="monthlyPriceNet"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppTextField label="Monatsabschlag - Brutto" name="monthlyPriceGross"
                                  type="text"
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{shrink: true}}
                                  onChange={(e) => onChangeFormField(e, formValues, setFormValues)}
                                  InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GasFormFields
