import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/styles'
import {connect, useDispatch} from "react-redux"
import {Grid} from "@material-ui/core"
import appActions from "../store/app/actions"
import measurementActions from "../store/measurements/actions"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import {withRouter} from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import DateRangePicker from "react-bootstrap-daterangepicker"
import Button from "@material-ui/core/Button"
import DateRangeIcon from "@material-ui/icons/DateRange"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import HighchartsReactFunctional from "../components/HighchartsReactFunctional"
import {appTs2HighchartTs, appTs2Moment, highchartsTs2AppTs, nowUnixTimestampInSeconds} from '../helperfunctions/date'
import MeasurementsService from '../services/MeasurementsService'


const measurementsService = new MeasurementsService()

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    }
}))

const Measurements = props => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const handleGroupingMeasurementsSwitch = event => {
        dispatch(appActions.updateSettings({groupingMeasurements: event.target.checked}))
    }

    const handleLegendItemClick = function (event) {
        event.preventDefault()
        dispatch(measurementActions.patchSeriesByIndex(this.index, {visible: !this.visible}))
    }

    const handleAfterSetExtremes = (value) => {
        dispatch(appActions.updateDateMeasurements(highchartsTs2AppTs(value.min), highchartsTs2AppTs(value.max)))
    }

    const chartOptions = measurementsService.getDefaultStockChartOptions(handleAfterSetExtremes, handleLegendItemClick)
    chartOptions.yAxis = props.options_yAxis
    chartOptions.plotOptions.series.stacking = props.appSettingsGroupingMeasurements === true ? "normal" : undefined
    chartOptions.series = props.options_series
    chartOptions.xAxis.min = appTs2HighchartTs(props.appSettingsDateMeasurementsMin)
    chartOptions.xAxis.max = appTs2HighchartTs(props.appSettingsDateMeasurementsMax)
    chartOptions.navigator.min = appTs2HighchartTs(props.appSettingsDateMeasurementsStart)
    chartOptions.navigator.max = appTs2HighchartTs(props.appSettingsDateMeasurementsEnd)


    useEffect(() => {
        if (props.showData === true) {
            dispatch(measurementActions.fetchSeriesData())
        }
    }, [dispatch, props.showData, props.measurementsSeriesIdsToLoadLength, props.appSettingsDateMeasurementsMin, props.appSettingsDateMeasurementsMax])

    let output = (props.measurementsSeriesIdsToLoadLength === 0)
        ? <div>Keine Daten ausgewählt.</div>
        : <HighchartsReactFunctional
            constructorType={'stockChart'}
            options={chartOptions}
            immutable={false}
        />

    const maxDate = props.appSettingsDateEnd !== null ? appTs2Moment(props.appSettingsDateEnd).format('DD.MM.YYYY') : appTs2Moment(nowUnixTimestampInSeconds())
    return (
        <div className={classes.root}>
            <div className="sticky-header">
                <div className="sticky-header__item">
                    <DateRangePicker
                        showDropdowns
                        onApply={(e, dateRangePicker) => dispatch(appActions.updateDateMeasurements(dateRangePicker.startDate.unix(), dateRangePicker.endDate.unix()))}
                        locale={{
                            direction: "ltr",
                            format: 'DD.MM.YYYY',
                            separator: " - ",
                            applyLabel: 'Auswählen',
                            cancelLabel: 'Abbrechen',
                            customRangeLabel: 'Benutzerdefiniert',
                            weekLabel: 'W',
                            daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                            monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                            firstDay: 1
                        }}
                        showWeekNumbers={true}
                        autoApply={true}
                        autoUpdateInput={true}
                        minDate={appTs2Moment(props.appSettingsDateStart).format('DD.MM.YYYY')}
                        maxDate={maxDate}
                        startDate={appTs2Moment(props.appSettingsDateMeasurementsMin).format('DD.MM.YYYY')}
                        endDate={appTs2Moment(props.appSettingsDateMeasurementsMax).format('DD.MM.YYYY')}
                        dateLimit={{"months": 1}}
                    >
                        <Button>
                            {appTs2Moment(props.appSettingsDateMeasurementsMin).format('DD.MM.YYYY')} - {appTs2Moment(props.appSettingsDateMeasurementsMax).format('DD.MM.YYYY')}
                            <DateRangeIcon style={{marginLeft: 15}} color={'primary'}/>
                        </Button>
                    </DateRangePicker>
                </div>
                <div className="sticky-header__item">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.appSettingsGroupingMeasurements === true}
                                onChange={handleGroupingMeasurementsSwitch}
                                name="groupingMeasurements"
                                color="primary"
                            />
                        }
                        label="Stacked"
                    />
                </div>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card className={classes.paper}>
                        <CardHeader title={props.title}/>
                        <CardContent>

                            {props.measurementsSeriesDataFetched === false &&
                            <LinearProgress/>
                            }

                            {output}

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        appSettingsGroupingMeasurements: state.app.settings.groupingMeasurements,
        appSettingsDateStart: state.app.settings.date.start,
        appSettingsDateEnd: state.app.settings.date.end,
        appSettingsDateMeasurementsStart: state.app.settings.dateMeasurements.start,
        appSettingsDateMeasurementsEnd: state.app.settings.dateMeasurements.end,
        appSettingsDateMeasurementsMin: state.app.settings.dateMeasurements.min,
        appSettingsDateMeasurementsMax: state.app.settings.dateMeasurements.max,
        measurementsSeriesDataFetched: state.measurements.seriesDataFetched,
        measurementsSeriesIdsToLoadLength: state.measurements.series.idsToLoad.length,
        showData: state.measurements.series.idsToLoad.length > 0 && state.app.settings.dateMeasurements.start > 0,
        options_yAxis: state.measurements.chartOptions.yAxis,
        options_series: state.measurements.chartOptions.series
    }
}


export default withRouter(connect(mapStateToProps, null)(Measurements))
