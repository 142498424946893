import Service from "./Service"
import {appTs2HighchartTs, nowUnixTimestampInSeconds} from "../helperfunctions/date"
import produce from "immer"
import NavigationService from "./NavigationService"
import _ from "lodash"

const navigationService = new NavigationService()

export default class EnergyService extends Service {

    getEnergyDataNormalized = energyData => {
        const normalizedObject = {
            categories: {byId: {}, allIds: []},
            charts: {byId: {}, allIds: []},
            series: {byId: {}, allIds: []}
        }

        for (const categoryItem of energyData) {

            // category - Endenergie | Primärenergie | CO2
            const category = {
                id: categoryItem.id,
                title: categoryItem.title,
                areSubItemsVisible: categoryItem.areSubItemsVisible,

                // navigation related stuff
                hasSubItemsToggle: categoryItem.hasSubItemsToggle,
                icon: categoryItem.title === 'Kosten' ? 'EuroIcon' : 'BarChartIcon',
                href: '/energie',
                charts: []
            }

            // chart - Stromverbrauch
            for (const chartItem of categoryItem.charts) {
                let chart = {
                    id: chartItem.id,
                    categoryId: categoryItem.id,
                    title: chartItem.title,
                    loading: true,
                    seriesDataFetched: false,
                    series: [],
                    visible: chartItem.visible,
                    yAxis: []
                }

                if((chartItem.chartoptions)) {
                    chart = _.merge({...chart}, chartItem.chartoptions)
                }

                const yAxisByKey = {}
                let currentYAxisCount = 0

                for (const currentYAxisKey in chartItem['axis']) {
                    const yAxisId = 'a' + ++currentYAxisCount

                    const currentYAxis = {
                        id: yAxisId,
                        title: {text: 'N/A'},
                        visible: true,
                        showEmpty: false,
                        opposite: false
                    }

                    if (chartItem['axis'].hasOwnProperty(currentYAxisKey) && chartItem['axis'][currentYAxisKey].hasOwnProperty('text')) {
                        currentYAxis.title.text = chartItem['axis'][currentYAxisKey].text
                    }

                    yAxisByKey[currentYAxisKey] = yAxisId

                    chart.yAxis.push(currentYAxis)
                }

                // series item - RLT/MSR | Heizung,  Wasser
                for (const seriesItem of chartItem.series) {
                    const seriesItemId = chart.id + '_' + seriesItem.title
                    const yAxisKey = seriesItem.axis

                    if (yAxisByKey[yAxisKey] === undefined) continue

                    let dataUrl = seriesItem.data

                    if (typeof dataUrl === 'string') {
                        dataUrl = dataUrl.replace('&callback=?', '')
                    }

                    chart.series.push(seriesItemId)
                    normalizedObject.series.allIds.push(seriesItemId)
                    normalizedObject.series.byId[seriesItemId] = {
                        id: seriesItemId,
                        type: seriesItem.type,
                        name: seriesItem.title,
                        yAxis: yAxisByKey[yAxisKey],
                        dataUrl,
                        data: [],
                        color: seriesItem.color,
                        tooltip: seriesItem.tooltip,
                        dataGrouping: {},
                        tempDataGroupingApproximation: seriesItem.dataGrouping.approximation ? seriesItem.dataGrouping.approximation : null
                    }
                }

                if (chart.yAxis.length > 0) {
                    category.charts.push(chart.id)
                    normalizedObject.charts.allIds.push(chart.id)
                    normalizedObject.charts.byId[chart.id] = chart
                }
            }

            if (category.charts.length > 0) { // TODO Frage an Kunden: immer anzeigen auch wenn keine Charts da sind?
                const charts = category.charts.map((chartId) => {
                    return normalizedObject.charts.byId[chartId]
                })
                category.subItemsCheckboxValue = navigationService.getSubItemsCheckboxValue(charts)
                normalizedObject.categories.allIds.push(category.id)
                normalizedObject.categories.byId[category.id] = category
            }
        }

        return normalizedObject
    }

    getUpdatedSeriesItem = (item, data, appDataGroupingUnit) => {
        return produce(item, draft => {
            draft.dataGrouping = {
                enabled: true,
                forced: true,
                units: [
                    [
                        appDataGroupingUnit,
                        [
                            1
                        ]
                    ]
                ],
                approximation: (item.tempDataGroupingApproximation !== null) ? item.tempDataGroupingApproximation : undefined
            }

            if (data !== undefined && data !== null && data !== 'undefined') {
                draft.data = data
            }
        })
    }

    getDefaultStockChartOptions = (chartStart, chartEnd, chartXAxisMin, chartXAxisMax, afterSetExtremes) => {
        const chartOptions = {
            boost: {
                allowForce: true,
                enabled: true,
                useGPUTranslations: true
            },
            chart: {
                zoomType: 'x'
            },
            legend: {
                enabled: true,
                align: 'center',
                layout: 'horizontal',
                verticalAlign: 'bottom'
            },
            rangeSelector: {
                selected: 0,
                enabled: false
            },
            navigator: {
                enabled: true,
                adaptToUpdatedData: false, // false for async data
                handles: {
                    height: 20,
                    width: 30
                },
                xAxis: {
                    min: undefined,
                    max: undefined
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            xAxis: {
                type: 'datetime',
                min: appTs2HighchartTs(chartXAxisMin),
                max: appTs2HighchartTs(chartXAxisMax),
                events: {
                    afterSetExtremes: afterSetExtremes
                }
            },
            credits: {
                enabled: false
            },
            scrollbar: {
                liveRedraw: false
            }
        }

        if (chartStart !== null && chartStart > 0) {
            chartOptions.navigator.xAxis.min = appTs2HighchartTs(chartStart)
        }

        if (chartEnd !== null && chartEnd > 0) {
            chartOptions.navigator.xAxis.max = appTs2HighchartTs(chartEnd)
        } else {
            chartOptions.navigator.xAxis.max = appTs2HighchartTs(nowUnixTimestampInSeconds())
        }

        return chartOptions
    }
}
