import moment from 'moment'

export const moment2AppTs = momentObject => momentObject.unix()//Math.floor(dateObject.valueOf() / 1000)
export const moment2HighchartsTs = dateObject => dateObject.valueOf()

export const appTs2Moment = unixTimestampInSeconds => moment.unix(unixTimestampInSeconds)
export const highchartsTs2Moment = unixTimestampInMilliseconds => moment(unixTimestampInMilliseconds)

export const appTs2HighchartTs = unixTimestampInSeconds => moment2HighchartsTs(appTs2Moment(unixTimestampInSeconds))
export const highchartsTs2AppTs = unixTimestampInMilliSeconds => moment2AppTs(highchartsTs2Moment(unixTimestampInMilliSeconds))

export const formatMomentForLog = momentObject => momentObject.format('DD.MM.YYYY')

export const nowUnixTimestampInSeconds = () => Math.floor(Date.now() / 1000)