import React, {useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {Grid} from '@material-ui/core'
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import CardActions from "@material-ui/core/CardActions"
import Card from "@material-ui/core/Card"
import * as Yup from "yup"
import authActions from "../store/auth/actions"
import {Form, Formik} from "formik"
import AppTextField from "../components/Form/AppTextField"
import {connect, useDispatch} from "react-redux"
import Texts from "../Texts";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}))


const Account = props => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [password] = useState('')
    const [newPassword] = useState('')
    const [newPasswordConfirm] = useState('')

    const formConfig = {
        initialValues: {
            vorname: props.authData.userVorname,
            nachname: props.authData.userNachname,
            email: props.authData.userEmail,
            plz: props.authData.userPlz,
            stadt: props.authData.userStadt,
            strasse: props.authData.userStrasse,
            telefon: props.authData.userTelefon,
            mobil: props.authData.userMobil
        },
        validationSchema:
            Yup.object(
                {
                    vorname: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure()
                        .max(128),
                    nachname: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure()
                        .max(128),
                    email: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure()
                        .email(Texts.form.error.validEmail)
                        .max(64),
                    telefon: Yup.string()
                        .ensure(),
                    mobil: Yup.string()
                        .ensure(),
                    strasse: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure(),
                    plz: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure()
                        .matches(/[0-9]{5}/,Texts.form.error.validZip),
                    stadt: Yup.string()
                        .ensure()
                        .required(Texts.form.error.required)
                }
            ),
        onSubmit: (values) => {
            dispatch(authActions.pushAccountData(values))
        }
    }

    const formPasswordConfig = {
        initialValues: {
            password: password,
            new_password: newPassword,
            new_password_confirm: newPasswordConfirm
        },
        validationSchema:
            Yup.object(
                {
                    password: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure(),
                    new_password: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure()
                        .min(7)
                        .max(128),
                    new_password_confirm: Yup.string()
                        .required(Texts.form.error.required)
                        .ensure()
                        .oneOf([Yup.ref('new_password'), null], Texts.form.error.passwordEqual)
                }
            ),
        onSubmit: (values) => {
            dispatch(authActions.pushPassword({password: values.password, new_password: values.new_password}))
        }
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={3}
            >
                <Grid
                item
                xs={12}
                >
                    <Card
                        className={null}
                    >
                        <Formik
                            initialValues={formConfig.initialValues}
                            validationSchema={formConfig.validationSchema}
                            onSubmit={formConfig.onSubmit}
                        >
                            <Form className={classes.form}>
                                <CardHeader
                                    subheader="Die Informationen können bearbeitet werden"
                                    title="Konto"
                                />
                                <Divider/>
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="Vorname" name="vorname" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="Nachname" name="nachname" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="E-Mail" name="email" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="Telefon" name="telefon" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="Mobil" name="mobil" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="Strasse" name="strasse" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="PLZ" name="plz" type="text" className={classes.textField}
                                                          margin="dense" fullWidth variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <AppTextField label="Stadt" name="stadt" type="text"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider/>
                                <CardActions>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        Kontodaten speichern
                                    </Button>
                                </CardActions>
                            </Form>
                        </Formik>
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{height: '100%'}}
                >
                    <Card
                        className={null}
                    >
                        <Formik
                            initialValues={formPasswordConfig.initialValues}
                            validationSchema={formPasswordConfig.validationSchema}
                            onSubmit={formPasswordConfig.onSubmit}
                        >
                            <Form className={classes.form}>
                                <CardHeader
                                    subheader="Hier können Sie ihr Passwort ändern"
                                    title="Passwort"
                                />
                                <Divider/>
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <AppTextField label="Aktuelles Passwort" name="password" type="password"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <AppTextField label="Neues Passwort" name="new_password" type="password"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <AppTextField label="Neues Passwort bestätigen" name="new_password_confirm" type="password"
                                                          className={classes.textField} margin="dense" fullWidth
                                                          variant="outlined"/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider/>
                                <CardActions>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                    >
                                        Passwort speichern
                                    </Button>
                                </CardActions>
                            </Form>
                        </Formik>
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Card
                        style={{
                            minHeight: 355,
                            height: '100%',
                            maxHeight: '100%',
                            backgroundImage: 'url(/images/splash.jpg)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}
                    >
                    </Card>
                </Grid>

            </Grid>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        authData: state.auth
    }
}

export default connect(mapStateToProps)(Account)
