import {createReducer} from "@reduxjs/toolkit"
import actions from './actions'

const initialState = {
    message: '',
    type: 'info', // error || warning || info || success
    show: false
}

export default createReducer(initialState, {
    [actions.notify]: (state, action) => {
        state.message = action.payload.message
        state.type = action.payload.type
        state.show = true
    },
    [actions.close]: (state, action) => {
        state.message = initialState.message
        state.type = initialState.type
        state.show = initialState.show
    }
})
