import {createAction} from "@reduxjs/toolkit"
import LocalStorageService from "../../services/LocalStorageService"
import appActions from "../app/actions"
import notificationActions from "../notification/actions"
import energyActions from "../energy/actions"
import measurementsActions from "../measurements/actions"
import ratesActions from "../rates/actions"
import dashboardActions from "../dashboard/actions"
import http from "../../axios"

const localStorageService = new LocalStorageService()

const login = (email, password, history = null) => {
    return async dispatch => {
        dispatch(loginStart())

        http.post('/login', {
            email: email,
            password: password
        })
            .then(response => {
                if (response.status === 200) {
                    dispatch(loginSuccess(response.data))
                    history.push('/dashboard')
                }
            }).catch(error => {

            let message = 'Fehler beim Login. Bitte versuchen Sie es erneut.'
            if (error.response && error.response.data.error) {
                // message = error.response.data.error
            }
            dispatch(loginFailure())
            dispatch(notificationActions.notify('error', message))
        })
    }
}

const setAuthData = createAction('auth/setAuthData', function prepare(authData) {

    if (authData.token) {
        localStorageService.setAccessToken(authData.token)
    }

    return {
        payload: {
            authData,
            token: localStorageService.getAccessToken()
        }
    }
})

const clearAuthData = createAction('auth/clearAuthData', function prepare() {
    localStorageService.clear()
    // http.__clearCache()
    // window.document.location.reload()
    return {}
})

const loginStart = createAction('auth/loginStart')

const loginSuccess = (data) => {
    return dispatch => {
        dispatch(appActions.updateSettings(data.settings, false))
        dispatch(setAuthData(data))
        dispatch(energyActions.fetchCategories())
        dispatch(measurementsActions.fetchMeasurements())
    }
}

const loginFailure = createAction('auth/loginFailure')

const logout = () => {
    return async dispatch => {
        dispatch(logoutStart())

        http.post('/logout')
            .then(response => {
                if (response.status === 200) {
                    dispatch(logoutSuccess())
                }
            })
            .catch(error => {
                console.log('auth error', error)
                dispatch(notificationActions.notify('error', 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.'))
                dispatch(logoutFailure())
            })
    }
}

const logoutStart = createAction('auth/logoutStart')

const logoutSuccess = () => {
    return dispatch => {

        dispatch(energyActions.reset())
        dispatch(measurementsActions.reset())
        dispatch(ratesActions.reset())
        dispatch(dashboardActions.reset())

        dispatch(clearAuthData())
        dispatch(reset())
    }
}

const logoutFailure = createAction('auth/logoutFailure')

const forgotPassword = (email, history = null) => {
    return async dispatch => {
        dispatch(forgotPasswordStart())

        let message = 'Danke sehr. Es wurde ein Link zum Zurücksetzen gesendet, sofern ein Konto für diese E-Mail-Adresse existiert.'

        http.post('/forgot-password', {
            email: email,
            targetPage: document.location.origin + '/reset-password/'
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    dispatch(forgotPasswordDone())
                    dispatch(notificationActions.notify('success', message))
                }
            }).catch(error => {

            message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.'
            dispatch(forgotPasswordDone())
            dispatch(notificationActions.notify('error', message))
        })
    }
}

const forgotPasswordStart = createAction('auth/forgotPasswordStart')
const forgotPasswordDone = createAction('auth/forgotPasswordDone')

const resetPassword = (token, password, history = null) => {
    return async dispatch => {
        dispatch(resetPasswordStart())

        let message = 'Passwort erfolgreich zurückgesetzt. Bitte loggen Sie sich mit Ihrem neuen Passwort ein.'

        http.post('/reset-password', {
            token,
            password
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    dispatch(resetPasswordDone())
                    dispatch(notificationActions.notify('success', message))
                    history.push('/login')
                }
            }).catch(error => {

            message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.'
            dispatch(resetPasswordDone())
            dispatch(notificationActions.notify('error', message))
        })
    }
}
const resetPasswordStart = createAction('auth/resetPasswordStart')
const resetPasswordDone = createAction('auth/resetPasswordDone')

const pushAccountData = (formValues) => {
    return async dispatch => {
        dispatch(pushAccountDataStart())

        let message = 'Kontodaten wurden erfolgreich gespeichert'

        http.patch('/me', formValues)
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    dispatch(pushAccountDataDone())
                    dispatch(notificationActions.notify('success', message))
                    dispatch(setAuthData(formValues))
                }
            }).catch(error => {

            message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.'
            dispatch(pushAccountDataDone())
            dispatch(notificationActions.notify('error', message))
        })
    }
}

const pushAccountDataStart = createAction('auth/pushAccountDataStart')
const pushAccountDataDone = createAction('auth/pushAccountDataDone')

const pushPassword = (formValues) => {
    return async dispatch => {
        dispatch(pushPasswordStart())

        let message = 'Passwort wurde erfolgreich geändert. Bitte loggen Sie sich mit Ihren neuen Zugangsdaten ein.'

        http.post('/change-password', formValues)
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    dispatch(pushPasswordDone())
                    dispatch(notificationActions.notify('success', message))
                    dispatch(logout())
                }
            }).catch(error => {

            message = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.'
            dispatch(pushPasswordDone())
            dispatch(notificationActions.notify('error', message))
        })
    }
}

const pushPasswordStart = createAction('auth/pushPasswordStart')
const pushPasswordDone = createAction('auth/pushPasswordDone')

const reset = createAction('auth/reset')

export default {
    setAuthData,
    clearAuthData,
    login,
    loginStart,
    loginSuccess,
    loginFailure,
    logout,
    logoutStart,
    logoutSuccess,
    logoutFailure,
    forgotPassword,
    forgotPasswordStart,
    forgotPasswordDone,
    resetPassword,
    resetPasswordStart,
    resetPasswordDone,
    pushAccountData,
    pushAccountDataStart,
    pushAccountDataDone,
    pushPassword,
    pushPasswordStart,
    pushPasswordDone
}
