import React from "react"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import {makeStyles} from "@material-ui/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import RatesServiceFactory from "../services/RatesServiceFactory"

const useStyles = makeStyles(theme => ({
    tableCellInherited: {
        // backgroundColor: '#fffa41'
        color: '#8e8e8e'
    },
    unit: {
        marginLeft: 5
        // borderRadius: 5,
        // padding: 5,
        // backgroundColor: 'orange'
    }
}))

const ratesServiceFactory = new RatesServiceFactory()

const RatesTable = props => {

    const classes = useStyles()
    const {ratesObj, priceMode} = props
    const editRowHandler = row => props.editRowHandler ? props.editRowHandler(row) : console.log('no edit handler')
    const deleteRowHandler = row => props.deleteRowHandler ? props.deleteRowHandler(row) : console.log('no delete handler')

    const type = props.type
    const specificRateService = ratesServiceFactory.getRateServiceByName(type)

    const tableData = specificRateService.getTable(ratesObj, priceMode)

    return (
        <Table stickyHeader aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell style={{width: 100}}></TableCell>
                    {tableData.header.columns.map(column => {
                        return (
                            <TableCell key={column.key} align={column.alignment}>{column.content}</TableCell>
                        )
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.body.rows.map(row => (
                    <TableRow hover key={row.key}>
                        <TableCell>
                            <IconButton aria-label="edit" className={classes.margin} size="small"
                                        onClick={() => editRowHandler(row)}>
                                <EditIcon fontSize="inherit" color="primary"/>
                            </IconButton>
                            <IconButton aria-label="delete" className={classes.margin} size="small"
                                        onClick={() => deleteRowHandler(row)}>
                                <DeleteIcon fontSize="inherit"/>
                            </IconButton>
                        </TableCell>

                        {row.columns.map(column => {
                            return (
                                <TableCell key={column.key} align={column.alignment} className={column.inherited ? classes.tableCellInherited : null}>
                                    {column.content}
                                    {(column.postContentString) ?
                                        <span className={classes.unit}>{column.postContentString}</span> :
                                        null
                                    }
                                </TableCell>
                            )

                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default RatesTable
