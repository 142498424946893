import React, {useMemo} from 'react'
import {makeStyles} from '@material-ui/styles'
import {Grid} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import {connect, useDispatch} from "react-redux"
import appActions from "../store/app/actions"
import Button from '@material-ui/core/Button'
import moment from "moment"

import DateRangeIcon from '@material-ui/icons/DateRange'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import StockChart from "../components/Energy/StockChart"
import {appTs2Moment} from '../helperfunctions/date'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}))

const Energy = props => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const dateRangePickerContent = useMemo(() => {
        const maxDate = props.appSettings.date.end !== null ? appTs2Moment(props.appSettings.date.end).format('DD.MM.YYYY') : null

        return (
            <div className="sticky-header__item">
                <DateRangePicker
                    showDropdowns
                    onApply={(e, dateRangePicker) => dispatch(appActions.updateSettings({
                        date: {
                            min: dateRangePicker.startDate.unix(),
                            max: dateRangePicker.endDate.unix()
                        }
                    }))}
                    locale={{
                        direction: "ltr",
                        format: 'DD.MM.YYYY',
                        separator: " - ",
                        applyLabel: 'Auswählen',
                        cancelLabel: 'Abbrechen',
                        customRangeLabel: 'Benutzerdefiniert',
                        weekLabel: 'W',
                        daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                        firstDay: 1
                    }}
                    showWeekNumbers={true}
                    autoApply={true}
                    autoUpdateInput={true}
                    showCustomRangeLabel={true}
                    ranges={{
                        'Heute': [moment(), moment()],
                        'Letzten 7 Tage': [moment().subtract(6, 'day'), moment()],
                        'Diesen Monat': [moment().startOf('month'), moment()],
                        'Letzten Monat': [moment().subtract(1, 'month').startOf('month'), moment()],
                        'Dieses Jahr': [moment().startOf('year'), moment()],
                        'YTD': [moment().subtract(1, 'year'), moment()]
                    }}
                    minDate={appTs2Moment(props.appSettings.date.start).format('DD.MM.YYYY')}
                    maxDate={maxDate}
                    startDate={appTs2Moment(props.appSettings.date.min).format('DD.MM.YYYY')}
                    endDate={appTs2Moment(props.appSettings.date.max).format('DD.MM.YYYY')}
                >
                    <Button>
                        {moment.unix(props.appSettings.date.min).format('DD.MM.YYYY')} - {moment.unix(props.appSettings.date.max).format('DD.MM.YYYY')}
                        <DateRangeIcon style={{marginLeft: 15}} color={'primary'}/>
                    </Button>
                </DateRangePicker>
            </div>
        )
    }, [props.appSettings.date.min, props.appSettings.date.max])

    const dataGroupingContent = useMemo(() => {
        return (
            <div className="sticky-header__item">
                <ToggleButtonGroup size="small" value={props.appSettings.dataGroupingUnit} exclusive
                                   onChange={(event, unit) => dispatch(appActions.updateSettings({dataGroupingUnit: unit}))}>
                    <ToggleButton key={1} value={'day'}>Tag</ToggleButton>
                    <ToggleButton key={2} value={'week'}>Woche</ToggleButton>
                    <ToggleButton key={3} value={'month'}>Monat</ToggleButton>
                    <ToggleButton key={4} value={'year'}>Jahr</ToggleButton>
                </ToggleButtonGroup>
            </div>
        )
    }, [props.appSettings.dataGroupingUnit])


    const chartsContent = useMemo(() => {
        return (
            <div className={classes.root}>
                <div className="sticky-header">
                    {dateRangePickerContent}
                    {dataGroupingContent}
                </div>

                <Grid container spacing={3}>
                    {props.charts.map(chart => {
                        return (
                            <Grid
                                item
                                lg={12}
                                xs={12}
                                key={chart.id}
                            >
                                <StockChart title={chart.title} chartId={chart.id}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }, [props.charts, dataGroupingContent, dateRangePickerContent])


    return (
        <div className={classes.root}>
            {chartsContent}
        </div>
    )
}

const mapStateToProps = state => {
    const charts = state.energy.categories.allIds.reduce((charts, categoryId) => {
        const energyCategory = state.energy.categories.byId[categoryId]
        energyCategory.charts.forEach(chartId => {
            const chart = state.energy.charts.byId[chartId]

            if (chart !== undefined && chart !== null && chart.title !== null && chart.visible !== false) {
                charts.push({
                    id: chart.id,
                    title: energyCategory.title + ' - ' + chart.title
                })
            }
        })

        return charts
    }, [])

    return {
        charts,
        appSettings: {
            date: {
                start: state.app.settings.date.start,
                end: state.app.settings.date.end,
                min: state.app.settings.date.min,
                max: state.app.settings.date.max
            },
            dataGroupingUnit: state.app.settings.dataGroupingUnit
        }
    }
}

export default connect(mapStateToProps)(Energy)
