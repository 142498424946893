import Service from "./Service"

export default class NavigationService extends Service {
    getSubItemsCheckboxValue = (subitems) => {
        const subitemsVisibleCount = subitems.reduce((visibleCount, subitem) => {
            visibleCount += (subitem.visible === true) ? 1 : 0
            return visibleCount
        }, 0)

        return subitemsVisibleCount === 0 ? 'not-checked' : (subitems.length === subitemsVisibleCount ? 'checked' : 'indeterminate') // checked | indeterminate | not-checked
    }

    getUpdatedCheckboxValueAndChildren = (parentCurrentCheckboxValue, children) => {
        const result = {
            parentUpdatedCheckboxValue: '',
            updatedChildren: []
        }

        switch (parentCurrentCheckboxValue) {
            case 'checked':
                result.parentUpdatedCheckboxValue = 'not-checked'
                for (let i = 0; i < children.length; i++) {
                    result.updatedChildren.push({id: children[i].id, visible: false})
                }
                break
            default:
                result.parentUpdatedCheckboxValue = 'checked'
                for (let i = 0; i < children.length; i++) {
                    result.updatedChildren.push({id: children[i].id, visible: true})
                }
        }

        return result
    }

}
