import React, {useEffect} from 'react'
import {Router} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {ThemeProvider} from '@material-ui/styles'

import theme from './theme'
import './assets/scss/index.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import Routes from './Routes'
import {connect, useDispatch} from "react-redux"
import appActions from "./store/app/actions"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import notificationActions from './store/notification/actions'
import LinearProgress from "@material-ui/core/LinearProgress"
import {Slide} from "@material-ui/core"

const browserHistory = createBrowserHistory()

const App = (props) => {
    const dispatch = useDispatch()
    const handleNotificationClose = () => dispatch(notificationActions.close())

    useEffect(() => {
        dispatch(appActions.initialize())
    }, [])

    return (
        <ThemeProvider theme={theme}>
            {
                props.appInitialized !== true
                    ? <LinearProgress/>
                    : <React.Fragment>
                        <Router history={browserHistory}>
                            <Routes/>
                        </Router>
                        <Snackbar open={props.notification.show} autoHideDuration={10000} onClose={handleNotificationClose}
                                  TransitionComponent={(transitionProps) => <Slide {...transitionProps} direction="up"/>}>
                            <Alert variant="filled" style={{fontSize: '14px'}} onClose={handleNotificationClose}
                                   severity={props.notification.type}>
                                {props.notification.message}
                            </Alert>
                        </Snackbar>
                    </React.Fragment>
            }
        </ThemeProvider>
    )
}

const mapStateToProps = state => {
    return {
        appInitialized: state.app.initialized,
        notification: state.notification
    }
}

export default connect(mapStateToProps)(App)
