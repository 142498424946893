import {createReducer} from "@reduxjs/toolkit"
import actions from './actions'
import RatesEnergyService from "../../services/RatesService"

const ratesService = new RatesEnergyService()

const initialState = {
    loading: false,
    error: '',
    showForm: false,
    data: {},
    priceMode: 'net' // net| gross
}

export default createReducer(initialState, {
    [actions.reset]: (state) => {
        state.loading = initialState.loading
        state.error = initialState.error
        state.showForm = initialState.showForm
        state.data = {...initialState.data}
        state.priceMode = initialState.priceMode
    },
    [actions.fetchRatesStart]: (state, action) => {
        state.loading = true
        state.error = ''
    },
    [actions.fetchRatesSuccess]: (state, action) => {
        const ratesDataNormalized = ratesService.getRatesDataNormalized(action.payload.ratesData)
        state.data[action.payload.type] = {
            ...ratesDataNormalized
        }
        state.loading = false
        state.error = ''
        state.showForm = false
    },
    [actions.fetchRatesFailure]: (state, action) => {
        state.loading = false
        state.error = action.payload.error
    },
    [actions.updateFormDataStart]: (state, action) => {
        state.loading = true
        state.error = ''
    },
    [actions.updateFormDataSuccess]: (state, action) => {
        const id = action.payload.data.id
        if (action.payload.type in state.data && state.data[action.payload.type].allIds.includes(id)) {
            state.loading = false
            state.error = ''
        }
    },
    [actions.updateFormDataFailure]: (state, action) => {
        state.loading = false
        state.error = action.payload.error
    },
    [actions.createFormDataStart]: (state, action) => {
        state.loading = true
        state.error = ''
    },
    [actions.createFormDataSuccess]: (state, action) => {
        state.loading = false
        state.error = ''
    },
    [actions.createFormDataFailure]: (state, action) => {
        state.loading = false
        state.error = action.payload.error
    },
    [actions.deleteFormDataStart]: (state, action) => {
        state.loading = true
        state.error = ''
    },
    [actions.deleteFormDataSuccess]: (state, action) => {
        state.loading = false
        state.error = ''
    },
    [actions.deleteFormDataFailure]: (state, action) => {
        state.loading = false
        state.error = action.payload.error
    },
    [actions.showForm]: (state, action) => {
        state.showForm = action.payload.show
    },
    [actions.setPriceMode]: (state, action) => {
        state.priceMode = action.payload.priceMode
    }
})
