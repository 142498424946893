import {createAction} from "@reduxjs/toolkit"
import EnergyService from "../../services/EnergyService"
import http from "../../axios"
import NavigationService from "../../services/NavigationService"

const navigationService = new NavigationService()
const energyService = new EnergyService()

const patchCharts = (patchObj) => {
    try {
        http.patch(`/energie/charts`, {data: patchObj})
    } catch (err) {
    }
}

const setChartVisibility = createAction('energy/setChartVisibility', function prepare(chartId, visible) {
    patchCharts([{id: chartId, visible}])
    return {
        payload: {
            chartId,
            visible
        }
    }
})

const setSubItemsCheckboxValue = createAction('energy/setSubItemsCheckboxValue', function prepare(categoryId, parentCurrentCheckboxValue, children) {
    const {
        parentUpdatedCheckboxValue,
        updatedChildren
    } = navigationService.getUpdatedCheckboxValueAndChildren(parentCurrentCheckboxValue, children)
    patchCharts(updatedChildren)
    return {
        payload: {
            categoryId,
            parentUpdatedCheckboxValue,
            updatedChildren
        }
    }
})


const patchCategory = (categoryId, patchObj) => {
    try {
        http.patch(`/energie/categories/${categoryId}`, patchObj)
    } catch (err) {
    }
}

const setCategoryAreSubItemsVisible = createAction('energy/setCategoryAreSubItemsVisible', function prepare(categoryId, areSubItemsVisible) {
    patchCategory(categoryId, {areSubItemsVisible})
    return {
        payload: {
            categoryId,
            areSubItemsVisible
        }
    }
})

const fetchCategories = () => {
    return async dispatch => {
        try {
            dispatch(fetchCategoriesStart())

            return http.get('/energie')
                .then(response => {
                    if (response.status === 200) {
                        dispatch(fetchCategoriesSuccess(response.data.data))
                    }
                    return true
                })
        } catch (err) {
            dispatch(fetchCategoriesFailure(err.toString()))
            return false
        }
    }
}

const fetchCategoriesStart = createAction('energy/fetchCategoriesStart')

const fetchCategoriesSuccess = createAction('energy/fetchCategoriesSuccess', function prepare(energyData) {
    return {
        payload: {
            energyData: energyData
        }
    }
})

const fetchCategoriesFailure = createAction('energy/fetchCategoriesFailure', function prepare(error) {
    return {
        payload: {
            error
        }
    }
})

const reset = createAction('energy/reset')


const getUpdatedSeriesDataProcess = (chartId) => {
    return (dispatch, getState) => {
        const state = getState()
        dispatch(getUpdatedSeriesDataStart(chartId))
        dispatch(getUpdatedSeriesData(state.energy.charts.byId[chartId].series, state.app.dataGroupingUnit))
        dispatch(getUpdatedSeriesDataFinish(chartId))
    }
}

const getUpdatedSeriesDataStart = createAction('energy/getUpdatedSeriesDataStart', function prepare(chartId) {
    return {
        payload: {
            chartId
        }
    }
})

const getUpdatedSeriesData = createAction('energy/getUpdatedSeriesData', function prepare(seriesIds, appDataGroupingUnit) {
    return {
        payload: {
            seriesIds,
            appDataGroupingUnit
        }
    }
})

const getUpdatedSeriesDataFinish = createAction('energy/getUpdatedSeriesDataFinish', function prepare(chartId) {
    return {
        payload: {
            chartId
        }
    }
})

const fetchSeriesData = (chartId) => {
    return async (dispatch, getState) => {
        try {
            const state = getState()

            const functionWithPromise = async item => {
                let itemData = []
                await http.get(item.dataUrl)
                    .then((response) => {
                        itemData = response.data
                    })
                return Promise.resolve(energyService.getUpdatedSeriesItem(item, itemData, state.app.dataGroupingUnit))
            }

            const anAsyncFunction = async item => {
                return await functionWithPromise(item)
            }

            const fetchData = async (series) => {
                return Promise.all(series.map(seriesItem => anAsyncFunction(seriesItem)))
            }

            // start
            dispatch(fetchSeriesDataStart(chartId))

            // get all series we want to fetch
            const seriesToFetch = state.energy.charts.byId[chartId].series.map((seriesId) => {
                return state.energy.series.byId[seriesId]
            })

            fetchData(seriesToFetch).then(series => {
                dispatch(fetchSeriesDataSuccess(chartId, series))
            })

        } catch (err) {
            console.log(err.toString())
            dispatch(fetchSeriesDataFailure(chartId, err.toString()))
        }
    }
}

const fetchSeriesDataStart = createAction('energy/fetchSeriesDataStart', function prepare(chartId) {
    return {
        payload: {
            chartId
        }
    }
})

const fetchSeriesDataSuccess = createAction('energy/fetchSeriesDataSuccess', function prepare(chartId, series) {
    return {
        payload: {
            chartId,
            series
        }
    }
})

const fetchSeriesDataFailure = createAction('energy/fetchSeriesDataFailure', function prepare(chartId, error) {
    return {
        payload: {
            chartId,
            error
        }
    }
})

const setSeriesItemVisibility = createAction('energy/setSeriesItemVisibility', function prepare(id, visible) {
    return {
        payload: {
            id,
            visible
        }
    }
})

export default {
    reset,
    setChartVisibility,
    patchCategory,
    setCategoryAreSubItemsVisible,
    fetchCategories,
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchSeriesData,
    fetchSeriesDataStart,
    fetchSeriesDataSuccess,
    fetchSeriesDataFailure,
    getUpdatedSeriesDataProcess,
    getUpdatedSeriesDataStart,
    getUpdatedSeriesData,
    getUpdatedSeriesDataFinish,
    setSubItemsCheckboxValue,
    setSeriesItemVisibility
}
