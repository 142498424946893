import {createAction} from "@reduxjs/toolkit"
import authActions from "../auth/actions"
import energyActions from "../energy/actions"
import measurementsActions from "../measurements/actions"
import {appTs2Moment, moment2AppTs} from "../../helperfunctions/date"
import moment from "moment"
import http from "../../axios"

const initialize = () => {

    return async dispatch => {
        dispatch(initializeStart())

        return http.get('/me')
            .then(async response => {
                if (response.status === 200) {
                    dispatch(authActions.setAuthData(response.data))
                    dispatch(updateSettings(response.data.settings, false))

                    await Promise.all([
                        dispatch(energyActions.fetchCategories()), // <-- async dispatch chaining in action
                        dispatch(measurementsActions.fetchMeasurements())// dispatch(measurementsActions.fetchMeasurements())
                    ])

                    dispatch(initializeDone())
                    return true
                }
                return true
            })
            .catch(response => {
                dispatch(initializeDone())
                return false
            })
    }
}
const initializeStart = createAction('app/initializeStart')
const initializeDone = createAction('app/initializeDone')

const patchSettings = (pushObj) => {
    try {
        const me = {
            settings: pushObj
        }
        http.patch('/me', me)

    } catch
        (err) {
    }
}

const updateDateMeasurements = (min, max, alterMax = false, doPatch = true) => {
    return (dispatch, getState) => {

        const state = getState()

        let minDate = appTs2Moment(min)
        let maxDate = appTs2Moment(max)

        if (alterMax === true) {
            const dataCollectionBeginDate = state.app.settings.date.start !== undefined && state.app.settings.date.start !== null ? appTs2Moment(state.app.settings.date.start) : null
            const dataCollectionEndDate = state.app.settings.date.end !== undefined && state.app.settings.date.end !== null ? appTs2Moment(state.app.settings.date.end) : null
            const dataCollectionDatesAvailable = dataCollectionBeginDate !== null && dataCollectionEndDate !== null

            if (dataCollectionDatesAvailable && (minDate < dataCollectionBeginDate || minDate > dataCollectionEndDate)) {
                minDate = moment(dataCollectionBeginDate)
            }

            let minPlus1MonthDate = moment(minDate).add(1, 'month')

            if (dataCollectionDatesAvailable && minPlus1MonthDate > dataCollectionEndDate) {
                minPlus1MonthDate = moment(dataCollectionEndDate)
            }

            if (maxDate > minPlus1MonthDate) {
                maxDate = moment(minPlus1MonthDate)
            }
        }

        let startDate = moment(minDate).subtract(1, 'week')
        let endDate = moment(maxDate).add(1, 'week')

        const settingsObj = {
            dateMeasurements: {
                start: moment2AppTs(startDate),
                end: moment2AppTs(endDate),
                min: moment2AppTs(minDate),
                max: moment2AppTs(maxDate)
            }
        }


        dispatch(updateSettings(settingsObj, doPatch))
    }
}

const updateSettings = (settingsObj, doPatch = true) => {
    return (dispatch, getState) => {
        const state = getState()

        let dataCollectionBeginDate = (settingsObj.date && settingsObj.date.start && settingsObj.date.start !== null) ? settingsObj.date.start : null


        if (settingsObj.date && settingsObj.date.start && settingsObj.date.start !== null && settingsObj.date.min) {
            settingsObj.date.min = (settingsObj.date.min === null || settingsObj.date.min < settingsObj.date.start) ? settingsObj.date.start : settingsObj.date.min
        }
        //
        //
        // const dataCollectionBeginDate = state.app.settings.date.start !== undefined && state.app.settings.date.start !== null ? appTs2Moment(state.app.settings.date.start) : null
        // const dataCollectionEndDate = state.app.settings.date.end !== undefined && state.app.settings.date.end !== null ? appTs2Moment(state.app.settings.date.end) : null


        if (doPatch === true) {
            patchSettings(settingsObj)
        }
        dispatch(updateSettingsDone(settingsObj))
    }
}

const updateSettingsDone = createAction('app/updateSettingsDone', function prepare(settingsObj) {
    return {
        payload: {
            settingsObj
        }
    }
})

const setLocation = createAction('app/setLocation', function prepare(lastLocation, currentLocation) {
    return {
        payload: {
            lastLocation,
            currentLocation
        }
    }
})

export default {
    patchSettings,
    initialize,
    initializeStart,
    initializeDone,
    updateSettings,
    updateDateMeasurements,
    updateSettingsDone,
    setLocation
}
