import {createReducer} from "@reduxjs/toolkit"
import actions from "./actions"

import DashboardService from "../../services/DashboardService"
import _ from "lodash"

const dashboardService = new DashboardService()

const initialState = {
    loading: false,
    realEstates: null,
    selectableWidgets: {byId: {}, allIds: []},
    widgets: {byId: {}, allIds: []},
    layouts: {},
    autoReloadEnabled: false
}

export default createReducer(initialState, {
    [actions.reset]: (state) => {
        state.loading = initialState.loading
        state.realEstates = initialState.realEstates
        state.selectableWidgets = {...initialState.selectableWidgets}
        state.widgets = {...initialState.widgets}
        state.layouts = {...initialState.layouts}
        state.autoReloadEnabled = initialState.autoReloadEnabled
    },
    [actions.fetchConfigSuccess]: (state, action) => {
        const {
            realEstates,
            selectableWidgets
        } = dashboardService.getRealEstatesAndSelectableWidgetsFromConfig(action.payload.config)
        state.realEstates = realEstates
        state.selectableWidgets = selectableWidgets
        state.autoReloadEnabled = true
    },
    [actions.fetchWidgetsSuccess]: (state, action) => {
        const widgets = {byId: {}, allIds: []}
        for (let i = 0; i < action.payload.widgets.length; i++) {
            const currWidget = action.payload.widgets[i]

            if (currWidget.type !== 'text') {
                const defaultOptions = dashboardService.getDefaultWidgetChartoptions(currWidget).chartOptions
                const customOptions = currWidget.chartOptions
                const mergedOptions = _.merge({...defaultOptions}, customOptions)

                console.log('DEFAULT', dashboardService.getDefaultWidgetChartoptions(currWidget).chartOptions)
                console.log('CUSTOM', currWidget.chartOptions)
                console.log('MERGED', mergedOptions)

                currWidget.chartOptions = mergedOptions
            }

            widgets.allIds.push(currWidget.id)
            widgets.byId[currWidget.id] = currWidget
        }
        state.widgets = widgets
        state.layouts = action.payload.layouts
    },
    [actions.addWidgetSuccess]: (state, action) => {
        state.widgets.allIds.unshift(action.payload.widget.id)
        state.widgets.byId[action.payload.widget.id] = action.payload.widget
    },
    [actions.patchDashboardLayoutsSuccess]: (state, action) => {
        state.layouts = action.payload.layouts
    },
    [actions.deleteWidgetSuccess]: (state, action) => {
        const indexOf = state.widgets.allIds.indexOf(action.payload.id)

        if (indexOf !== -1) {
            state.widgets.allIds.splice(indexOf, 1)
            delete state.widgets.byId[action.payload.id]
        }
    }
})
