import React from 'react'
import {Redirect, Switch} from 'react-router-dom'

import ForgotPassword from "./pages/ForgotPassword"
import SignIn from './pages/SignIn'
import ResetPassword from "./pages/ResetPassword"

import Home from "./pages/Home"
import Dashboard from "./pages/Dashboard"
import Energy from "./pages/Energy"
import Rates from "./pages/Rates"
import NotFound from "./pages/NotFound"
import Account from "./pages/Account"
import Measurements from "./pages/Measurements"
import RouteWithLayout from "./components/RouteWithLayout/RouteWithLayout"

const Routes = () => {
    return (
        <Switch>
            <RouteWithLayout component={Home} exact path="/" useMinimalLayout={true} isProtected={true}/>
            <RouteWithLayout component={Dashboard} exact path="/dashboard" useMinimalLayout={false} isProtected={true} showFooterText={false}/>
            <RouteWithLayout component={Energy} exact path="/energie" useMinimalLayout={false} isProtected={true}/>
            <RouteWithLayout component={Measurements} exact path="/messwerte" useMinimalLayout={false}
                             isProtected={true}/>
            <RouteWithLayout component={Account} exact path="/account" useMinimalLayout={false} isProtected={true}/>

            <RouteWithLayout component={Rates} exact path="/tarife/:type" useMinimalLayout={false} isProtected={true}/>

            <RouteWithLayout component={SignIn} exact path="/login" useMinimalLayout={true} isProtected={false}/>
            <RouteWithLayout component={ForgotPassword} exact path="/forgot-password" useMinimalLayout={true}
                             isProtected={false}/>
            <RouteWithLayout component={ResetPassword} exact path="/reset-password/:token" useMinimalLayout={true}
                             isProtected={false}/>

            <RouteWithLayout component={NotFound} exact path="/not-found" useMinimalLayout={true} isProtected={false}/>
            <Redirect to="/not-found"/>
        </Switch>
    )
}

export default Routes
