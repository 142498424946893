import React from 'react'
import {connect, useDispatch} from "react-redux"
import authActions from "../store/auth/actions"
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {
    Grid,
    Button,
    IconButton,
    Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CircularProgress from "@material-ui/core/CircularProgress"
import Link from "@material-ui/core/Link"
import {Form, Formik} from "formik"
import * as Yup from 'yup'
import AppTextField from "../components/Form/AppTextField"
import teaserImage from "../assets/images/auth.jpg"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#4451B5',
        backgroundImage: `url(${teaserImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    suggestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    submitButton: {
        margin: theme.spacing(2, 0)
    }
}))

const SignIn = props => {
    const dispatch = useDispatch()
    const {history} = props;

    const classes = useStyles();

    const handleBack = () => {
        history.goBack();
    }

    const formConfig = {
        initialValues: {email: '', password: ''},
        validationSchema:
            Yup.object(
                {
                    email: Yup.string()
                        .required('Bitte füllen Sie dieses Feld aus.')
                        .max(64, '')
                        .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.'),
                    password: Yup.string()
                        .required('Bitte füllen Sie dieses Feld aus.')
                        .max(128)
                }
            ),
        onSubmit: (values) => {
            dispatch(authActions.login(values.email, values.password, history))
        }
    }

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteContainer}
                    item
                    lg={5}
                >
                    <div className={classes.quote}>
                        <div className={classes.quoteInner}>
                            <Typography
                                className={classes.quoteText}
                                variant="h1"
                            >
                                Energiemonitoring <br/>
                                mit Mehrwert
                            </Typography>
                            <div className={classes.person}>
                                <Typography
                                    className={classes.name}
                                    variant="body1"
                                >
                                    Geeignet für Multi-Liegenschaft, Wohnungswirtschaft, Filialbetrieb oder
                                    Einzelgebäude
                                </Typography>
                                <Typography
                                    className={classes.bio}
                                    variant="body2"
                                >
                                    by emonit GmbH
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid
                    className={classes.content}
                    item
                    lg={7}
                    xs={12}
                >
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            <Formik
                                initialValues={formConfig.initialValues}
                                validationSchema={formConfig.validationSchema}
                                onSubmit={formConfig.onSubmit}
                            >
                                <Form className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h1"
                                    >
                                        Login
                                    </Typography>
                                    <Typography
                                        className={classes.suggestion}
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        Melden Sie sich an
                                    </Typography>
                                    <AppTextField label="E-Mail" name="email" type="text" className={classes.textField} fullWidth variant="outlined" autoFocus/>
                                    <AppTextField label="Passwort" name="password" type="password" className={classes.textField} fullWidth variant="outlined" />
                                    <Button
                                        className={classes.submitButton}
                                        color="primary"
                                        disabled={false}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {props.authLoading ?
                                            <CircularProgress style={{color: '#FFFFFF', height: 30, width: 30}}/> : 'Login'}
                                    </Button>

                                    <Typography
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        <Link
                                            component={RouterLink}
                                            to="/forgot-password"
                                            variant="h6"
                                        >
                                            Passwort vergessen?
                                        </Link>
                                    </Typography>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

SignIn.propTypes = {
    history: PropTypes.object
};

const mapStateToProps = state => {
    return {
        authLoading: state.auth.loading
    }
}

export default connect(mapStateToProps, null)(SignIn)
