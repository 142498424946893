import Service from "./Service"
import measurementActions from "../store/measurements/actions"
import produce from "immer"
import NavigationService from "./NavigationService"

const navigationService = new NavigationService()

export default class MeasurementsService extends Service {

    getMeasurementsDataNormalized = (responseData) => {
        const state = {
            navigation: [],
            series: {
                allIds: [],
                idsToLoad: []
            },
            yAxis: {},
            initialSeriesData: []
        }

        const auxVisibility = {}

        // navigation
        if (responseData.menu) {
            state.navigation = Object.keys(responseData.menu).map(sectionTitle => {
                const section = {
                    id: sectionTitle,
                    title: sectionTitle,
                    areSubItemsVisible: false,
                    hasSubItemsToggle: true,
                    icon: 'BarChartIcon',
                    href: '/messwerte',
                    children: []
                }

                if ("series" in responseData.menu[sectionTitle]) {
                    responseData.menu[sectionTitle].series.forEach(item => {
                        section.children.push(this.getNormalizedSeriesItem(item))
                        auxVisibility[item.id] = item.visible
                    })
                }

                if ("subcategories" in responseData.menu[sectionTitle]) {
                    responseData.menu[sectionTitle].subcategories.forEach(subcategoryRaw => {
                        const subcategory = {
                            type: 'sub',
                            id: subcategoryRaw.title,
                            title: subcategoryRaw.title,
                            areSubItemsVisible: false,
                            hasSubItemsToggle: true,
                            subItemsCheckboxValue: navigationService.getSubItemsCheckboxValue(subcategoryRaw.series),
                            children: []
                        }

                        if ("series" in subcategoryRaw) {
                            subcategoryRaw.series.forEach(item => {
                                subcategory.children.push(this.getNormalizedSeriesItem(item))
                                auxVisibility[item.id] = item.visible
                            })
                        }

                        section.children.push(subcategory)
                    })
                }
                return section
            })
        }


        // series
        if (responseData.messwerte) {
            Object.keys(responseData.messwerte).forEach(seriesId => {
                const id = seriesId.toString()
                const visible = auxVisibility.hasOwnProperty(id) && auxVisibility[id] === true

                state.series.allIds.push(id)
                state.initialSeriesData.push(produce(responseData.messwerte[seriesId], draft => {
                    draft.id = id
                    draft.dataUrl = draft.data.replace('&callback=?', '')
                    draft.data = []
                    draft.name = draft.title
                    draft.visible = visible
                }))

                // idsToLoad
                if (visible === true) {
                    state.series.idsToLoad.push(id)
                }
            })
        }

        // yAxis
        if (responseData.axis) {
            state.yAxis = this.getChartYAxisNormalized(responseData.axis)
        }

        return state
    }

    getChartYAxisNormalized = yAxisData => {
        return Object.keys(yAxisData).map(key => {
            return {
                id: key,
                title: {text: yAxisData[key].text},
                visible: yAxisData[key].visible,
                showEmpty: yAxisData[key].showEmpty,
                opposite: yAxisData[key].opposite
            }
        })
    }

    getUpdatedSeriesItem = (item, data = null, dispatch) => {
        return produce(item, draft => {
            draft.events = {
                show: () => dispatch(measurementActions.patchSeries(draft.id, {visible: true})),
                hide: () => dispatch(measurementActions.patchSeries(draft.id, {visible: false}))
            }

            if (data !== undefined && data !== null && data !== 'undefined') {
                draft.data = data
            }

        })
    }

    getDefaultStockChartOptions = (afterSetExtremes, legendItemClick) => {
        return {
            yAxis: [],
            series: [],
            boost: {
                allowForce: true,
                enabled: true,
                useGPUTranslations: true
            },
            plotOptions: {
                series: {
                    stacking: undefined,
                    events: {
                        legendItemClick
                    }
                }
            },
            zoomType: 'x',
            title: {text: 'Messwerte'},
            xAxis: {
                type: 'datetime',
                ordinal: 0,
                minRange: 3600 * 1000, // one hour
                maxRange: 3600 * 1000 * 24 * 30, // one month
                min: null,
                max: null,
                events: {
                    afterSetExtremes
                }
            },
            chart: {
                animation: false,
                shadow: false,
                zoomType: 'x',
                height: 800
            },
            navigator: {
                xAxis: {
                    min: null,
                    max: null
                }
            },
            tooltip: {
                shared: true,
                split: false
            },
            legend: {
                enabled: true,
                align: 'center',
                layout: 'horizontal',
                verticalAlign: 'bottom'
            },
            credits: {
                enabled: false
            },
            scrollbar: {
                liveRedraw: false
            },
            rangeSelector: {
                buttons: [
                    {
                        type: 'hour',
                        count: 1,
                        text: '1h'
                    },
                    {
                        type: 'hour',
                        count: 4,
                        text: '4h'
                    },
                    {
                        type: 'hour',
                        count: 8,
                        text: '8h'
                    },
                    {

                        type: 'day',
                        count: 1,
                        text: '24h'
                    },
                    {
                        type: 'day',
                        count: 2,
                        text: '2d'
                    },
                    {
                        type: 'day',
                        count: 3,
                        text: '3d'
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1W'
                    },
                    {
                        type: 'week',
                        count: 2,
                        text: '2W'
                    },
                    {
                        type: 'week',
                        count: 4,
                        text: '1M'
                    }
                ],
                inputEnabled: false, // it supports only days
                inputBoxWidth: 140,
                inputDateFormat: '%d.%m.%Y %H:%M:%S',
                inputEditDateFormat: '%d.%m.%Y %H:%M:%S'
            }
        }
    }

    getNormalizedSeriesItem = (series) => {
        return produce(series, draft => {
            draft.id = draft.id.toString()
            draft.title = draft.name
            draft.type = 'series'
        })
    }
}
