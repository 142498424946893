import {createAction} from "@reduxjs/toolkit";

const notify = createAction('notification/notify', function prepare(type, message) {
    return {
        payload: {
            type,
            message
        }
    }
})

const close = createAction('notification/close')

export default {
    notify,
    close
}
