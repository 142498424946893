import {createAction} from "@reduxjs/toolkit"
import notificationActions from "../notification/actions"
import RatesServiceFactory from "../../services/RatesServiceFactory"
import http from "../../axios"

const ratesServiceFactory = new RatesServiceFactory()

const deleteFormData = (type, id) => {
    return async dispatch => {
        dispatch(deleteFormDataStart(type))

        let message = 'Datensatz erfolgreich gelöscht.'
        let messageType = 'success'
        const ratesService = ratesServiceFactory.getRateServiceByName(type)

        http.delete(ratesService.getUrl('delete').replace('###ID###', id))
            .then(response => {
                dispatch(deleteFormDataSuccess(type, id))
                dispatch(fetchRates(type))
            })
            .catch(error => {
                message = 'Fehler beim Löschen des Datensatzes.'
                messageType = 'error'
                dispatch(deleteFormDataFailure(type, message))
            })
            .finally(() => {
                dispatch(notificationActions.notify(messageType, message))
            })
    }
}

const deleteFormDataStart = createAction('energy/deleteFormDataStart', function prepare(type) {
    return {
        payload: {
            type
        }
    }
})

const deleteFormDataSuccess = createAction('energy/deleteFormDataSuccess', function prepare(type, id) {
    return {
        payload: {
            type,
            id
        }
    }
})

const deleteFormDataFailure = createAction('energy/deleteFormDataFailure', function prepare(type, error) {
    return {
        payload: {
            type,
            error
        }
    }
})

const createFormData = (type, data) => {
    return async dispatch => {
        dispatch(createFormDataStart(type))

        let message = 'Erstellen des Datensatzes erfolgreich.'
        let messageType = 'success'

        const ratesService = ratesServiceFactory.getRateServiceByName(type)
        const finalData = ratesService.getFinalFormData(data)

        http.post(ratesService.getUrl('create'), {
            ...finalData,
            type
        })
            .then(response => {
                dispatch(createFormDataSuccess(type, data))
                dispatch(fetchRates(type))
            })
            .catch(error => {
                message = 'Fehler beim Erstellen des Datensatzes.'
                messageType = 'error'
                dispatch(createFormDataFailure(type, message))
            })
            .finally(() => {
                dispatch(notificationActions.notify(messageType, message))
            })
    }
}

const createFormDataStart = createAction('energy/createFormDataStart', function prepare(type) {
    return {
        payload: {
            type
        }
    }
})

const createFormDataSuccess = createAction('energy/createFormDataSuccess', function prepare(type, data) {
    return {
        payload: {
            type,
            data
        }
    }
})

const createFormDataFailure = createAction('energy/createFormDataFailure', function prepare(type, error) {
    return {
        payload: {
            type,
            error
        }
    }
})

const updateFormData = (type, data) => {
    return async dispatch => {
        dispatch(updateFormDataStart(type))
        let message = 'Datensatz erfolgreich gespeichert.'
        let messageType = 'success'

        const ratesService = ratesServiceFactory.getRateServiceByName(type)
        const finalData = ratesService.getFinalFormData(data)

        http.patch(ratesService.getUrl('update').replace('###ID###', data.id), finalData)
            .then(response => {
                dispatch(updateFormDataSuccess(type, data))
                dispatch(fetchRates(type))
            })
            .catch(error => {
                message = 'Fehler beim Speichern des Datensatzes.'
                messageType = 'error'
                dispatch(updateFormDataFailure(type, message))
            })
            .finally(() => {
                dispatch(notificationActions.notify(messageType, message))
            })
    }
}

const updateFormDataStart = createAction('energy/updateFormDataStart', function prepare(type) {
    return {
        payload: {
            type
        }
    }
})

const updateFormDataSuccess = createAction('energy/updateFormDataSuccess', function prepare(type, data) {
    return {
        payload: {
            type,
            data
        }
    }
})

const updateFormDataFailure = createAction('energy/updateFormDataFailure', function prepare(type, error) {
    return {
        payload: {
            type,
            error
        }
    }
})

const fetchRates = (type) => {
    return async dispatch => {
        dispatch(fetchRatesStart())
        const ratesService = ratesServiceFactory.getRateServiceByName(type)
        http.get(ratesService.getUrl('list'))
            .then(response => {
                dispatch(fetchRatesSuccess(type, response.data.data))
            })
            .catch(error => {
                let message = 'Fehler beim Laden der Daten. Bitte versuchen Sie es noch einmal.'
                dispatch(fetchRatesFailure(type, message))
                dispatch(notificationActions.notify('error', message))
            })
    }
}

const fetchRatesStart = createAction('rates/fetchRatesStart')

const fetchRatesSuccess = createAction('rates/fetchRatesSuccess', function prepare(type, ratesData) {
    return {
        payload: {
            type,
            ratesData
        }
    }
})

const fetchRatesFailure = createAction('rates/fetchRatesFailure', function prepare(type, error) {
    return {
        payload: {
            type,
            error
        }
    }
})

const showForm = createAction('rates/showForm', function prepare(show) {
    return {
        payload: {
            show
        }
    }
})

const setPriceMode = createAction('rates/setPriceMode', function prepare(priceMode) {
    return {
        payload: {
            priceMode
        }
    }
})

const reset = createAction('rates/reset')


export default {
    reset,
    deleteFormData,
    deleteFormDataStart,
    deleteFormDataSuccess,
    deleteFormDataFailure,
    createFormData,
    createFormDataStart,
    createFormDataSuccess,
    createFormDataFailure,
    updateFormData,
    updateFormDataStart,
    updateFormDataSuccess,
    updateFormDataFailure,
    fetchRates,
    fetchRatesStart,
    fetchRatesSuccess,
    fetchRatesFailure,
    showForm,
    setPriceMode
}
