import React from 'react'
import {makeStyles} from '@material-ui/styles'
import {Grid, Typography} from '@material-ui/core'
import {NavLink} from 'react-router-dom'
import logo from '../assets/images/logos/emonit_schwarz.png'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        paddingTop: 150,
        textAlign: 'center'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    }
}));

const Home = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                justify="center"
                spacing={4}
            >
                <Grid
                    item
                    lg={6}
                    xs={12}
                >
                    <div className={classes.content}>
                        <Typography variant="h1">
                            Willkommen bei der "beestapp" der emonit GmbH
                        </Typography>
                        <Typography variant="h2">
                            Energiemonitoring mit Mehrwert
                        </Typography>
                        <img
                            alt="Under development"
                            className={classes.image}
                            style={{maxWidth: 139, marginBottom: 50}}
                            src={logo}
                        />
                        <Typography variant="subtitle1">
                            Diese Anwendung befindet sich derzeit in der Entwicklung!
                        </Typography>
                        <NavLink to="/dashboard">Dashboard</NavLink>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Home;
