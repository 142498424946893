import React from 'react'
import {connect, useDispatch} from "react-redux"
import authActions from "../store/auth/actions"
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Button, Grid, IconButton, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CircularProgress from "@material-ui/core/CircularProgress"
import {Form, Formik} from "formik"
import AppTextField from "../components/Form/AppTextField"
import * as Yup from "yup"
import Texts from "../Texts"
import ImageGridItem from "../components/ImageGridItem";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    suggestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    submitButton: {
        margin: theme.spacing(2, 0)
    }
}))

const ForgotPassword = props => {
    const dispatch = useDispatch()
    const {history} = props;

    const classes = useStyles();

    const handleBack = () => {
        history.goBack();
    }

    const formConfig = {
        initialValues: {email: ''},
        validationSchema:
            Yup.object(
                {
                    email: Yup.string()
                        .required(Texts.form.error.required)
                        .max(64, '')
                        .email(Texts.form.error.validEmail)
                }
            ),
        onSubmit: (values) => {
            dispatch(authActions.forgotPassword(values.email, history))
        }
    }

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <ImageGridItem/>
                <Grid className={classes.content} item lg={7} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentHeader}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon/>
                            </IconButton>
                        </div>
                        <div className={classes.contentBody}>
                            <Formik
                                initialValues={formConfig.initialValues}
                                validationSchema={formConfig.validationSchema}
                                onSubmit={formConfig.onSubmit}
                            >
                                <Form className={classes.form}>
                                    <Typography
                                        className={classes.title}
                                        variant="h1"
                                    >
                                        {Texts.form.forgotPasswordForm.title}
                                    </Typography>
                                    <Typography
                                        className={classes.suggestion}
                                        color="textSecondary"
                                        variant="body1"
                                    >
                                        {Texts.form.forgotPasswordForm.instructions}
                                    </Typography>
                                    <AppTextField label={Texts.form.label.email} name="email" type="text"
                                                  className={classes.textField} fullWidth variant="outlined" autoFocus/>
                                    <Button
                                        className={classes.submitButton}
                                        color="primary"
                                        disabled={false}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {props.authLoading ?
                                            <CircularProgress style={{
                                                color: '#FFFFFF',
                                                height: 30,
                                                width: 30
                                            }}/> : Texts.form.forgotPasswordForm.submitButton}
                                    </Button>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

ForgotPassword.propTypes = {
    history: PropTypes.object
};

const mapStateToProps = state => {
    return {
        authLoading: state.auth.loading
    }
}

export default connect(mapStateToProps)(ForgotPassword)
