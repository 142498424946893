const Texts = {
    form: {
        error: {
            validEmail: "Geben Sie eine gültige E-Mail-Adresse ein.",
            validZip: "Geben Sie eine gültige Postleitzahl ein.",
            required: "Bitte füllen Sie dieses Feld aus.",
            passwordEqual: "Passwörter müssen übereinstimmen"
        },
        label: {
            email: "E-Mail",
            password: "Passwort",
            passwordConfirm: "Passwort bestätigen"
        },
        forgotPasswordForm: {
            title: "Passwort vergessen?",
            instructions: "Bitte geben Sie unten Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen des Passworts zu erhalten.",
            submitButton: "Mein Passwort zurücksetzen"
        },
        resetPasswordForm: {
            title: "Passwort zurücksezten",
            instructions: "Bitte geben Sie ein neues Passwort ein und bestätigen Sie dieses.",
            submitButton: "Mein Passwort zurücksetzen"
        }
    }
}

export default Texts
