import React, {Fragment} from "react"
import {useField} from "formik"
import TextField from "@material-ui/core/TextField"

const AppDateField = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <Fragment>
            <TextField

                error={!!(meta.touched && meta.error)}
                helperText={
                    meta.touched && meta.error ? meta.error : null
                }
                onChange={field.onChange}
                label={label}
                {...field}
                {...props}
            />
        </Fragment>

    )
}

export default AppDateField
