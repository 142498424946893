import React, {useEffect} from "react"
import {makeStyles} from "@material-ui/styles"
import {connect, useDispatch} from "react-redux"
import Grid from "@material-ui/core/Grid"
import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import HighchartsReactFunctional from "../components/HighchartsReactFunctional"
import AddIcon from "@material-ui/icons/Add"
import {Button} from "@material-ui/core"
import dashboardActions from "../store/dashboard/actions"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import DeleteIcon from "@material-ui/icons/Delete"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldsIcon from "@material-ui/icons/TextFields"
import ShowChartIcon from "@material-ui/icons/ShowChart"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"
import {Responsive, WidthProvider} from 'react-grid-layout'
import {Textfit} from 'react-textfit'
import {MultilineChart} from "@material-ui/icons"

const ResponsiveGridLayout = WidthProvider(Responsive)

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        height: "100%"
    },
    widget: {
        position: 'relative',
        "& h2": {
            marginBottom: "10px"
        },
        "& .content": {
            height: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            backgroundColor: 'white'
        },
        "& h3": {},
        "& .value": {
            "&__main": {},
            "&__additional": {
                fontSize: "70%"
            }
        },
        "&.react-draggable": {
            cursor: "grab",
            "&.react-draggable-dragging": {
                cursor: "grabbing"
            }
        }
    },
    widgetActions: {
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 99
    },
    widgetChartContainer: {
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        '& div': {
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            maxWidth: '100%'
        }
    },
    icon: {
        marginRight: theme.spacing(2)
    },
    fab: {
        margin: 0,
        top: "auto",
        right: theme.spacing(3),
        bottom: theme.spacing(3),
        left: "auto",
        position: "fixed"
    }
}))

let autoReloadInterval = null

const Dashboard = props => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const loadWidgetInterval = 5 * 1000

    const [editMode, setEditMode] = React.useState(false)
    const [selectedWidget, setSelectedWidget] = React.useState(undefined)
    const [widgetIdToDelete, setWidgetIdToDelete] = React.useState(null)

    const handleEditModeSwitch = event => {
        setEditMode(event.target.checked)
        setSelectedWidget(undefined)
    }

    const handleWidgetAutoComplete = (event, value) => {
        if (value == null) {
            return
        }

        setSelectedWidget(value)
    }

    const handleCloseDeleteDialog = () => {
        setWidgetIdToDelete(null)
    }

    const addWidget = () => {
        if (selectedWidget != null) {
            dispatch(dashboardActions.addWidget(selectedWidget))
            setSelectedWidget(undefined)
        }
    }

    const deleteWidget = (id) => {
        setWidgetIdToDelete(id)
        if (widgetIdToDelete != null) {
            dispatch(dashboardActions.deleteWidget(widgetIdToDelete))
            handleCloseDeleteDialog()
        }
    }

    const onLayoutChange = (layout, layouts) => {
        if (props.dashboardWidgets.allIds.length > 0) {
            dispatch(dashboardActions.patchDashboardLayouts(layouts))
        }
    }

    const clearAutoReloadInterval = () => {
        clearInterval(autoReloadInterval)
    }

    useEffect(() => {
        dispatch(dashboardActions.fetchConfig())
    }, [])

    useEffect(() => {
        if (props.dashboardAutoReloadEnabled === true) {
            dispatch(dashboardActions.fetchWidgets())
            autoReloadInterval = setInterval(() => {
                dispatch(dashboardActions.fetchWidgets())
            }, loadWidgetInterval)
        }
        return () => clearAutoReloadInterval()
    }, [props.dashboardAutoReloadEnabled])


    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <h1>Dashboard</h1>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editMode === true}
                                onChange={handleEditModeSwitch}
                                name="editMode"
                                color="primary"
                            />
                        }
                        label="Bearbeiten"
                    />
                </Grid>
            </Grid>
            {(editMode === true && props.dashboardSelectableWidgets.length > 0) &&
            <Grid container>
                <Grid item xs={12} sm={6}>


                    <Autocomplete
                        size="medium"
                        options={props.dashboardSelectableWidgets}
                        renderOption={(option) => {
                            let icon = null
                            if (option.type === "text") {
                                icon = <TextFieldsIcon className={classes.icon}/>
                            } else if (option.type === "graph") {
                                icon = <ShowChartIcon className={classes.icon}/>
                            } else if (option.type === "areasplinerange") {
                                icon = <MultilineChart className={classes.icon}/>
                            } else {
                                icon = <ShowChartIcon className={classes.icon}/>
                            }
                            return <Grid container alignItems="center">
                                <Grid item>
                                    {icon}
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" color="textSecondary">
                                        {option.pathText}
                                    </Typography>
                                    {option.description}
                                </Grid>
                            </Grid>
                        }}
                        groupBy={(option) => option.targetTitle}
                        getOptionLabel={(option) => option.text}
                        style={{marginRight: 10}}
                        renderInput={(params) => <TextField {...params} label="Widget auswählen"
                                                            variant="outlined"/>}
                        // inputValue={selectedWidget}
                        onChange={handleWidgetAutoComplete}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button size="medium" variant="contained" color="primary" startIcon={<AddIcon/>}
                            disabled={selectedWidget === undefined} onClick={addWidget}>
                        Hinzufügen
                    </Button>
                </Grid>

            </Grid>
            }
            <Grid container>
                <Grid item style={{width: "100%"}}>
                    <div>
                        <ResponsiveGridLayout
                            className="layout"
                            rowHeight={60}
                            isDraggable={editMode}
                            isResizable={editMode}
                            isBounded={false}
                            onLayoutChange={onLayoutChange}
                            breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                            cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                            layouts={props.dashboardLayouts}
                        >
                            {props.dashboardWidgets.allIds.map(widgetId => {
                                const widget = props.dashboardWidgets.byId[widgetId]

                                return (
                                    <Card key={widget.id} className={classes.widget}>

                                        <CardContent className="content">
                                            {editMode &&
                                            <div className={classes.widgetActions}>
                                                <Button size="small" startIcon={<DeleteIcon/>}
                                                        style={{color: "#e65100"}} onClick={(e) => {
                                                    e.stopPropagation()
                                                    deleteWidget(widget.id)
                                                }}>
                                                    Löschen
                                                </Button>
                                            </div>
                                            }

                                            {widget.title && widget.title.length > 0 &&
                                            <Typography variant="h5" component="h2">
                                                {widget.title}
                                            </Typography>
                                            }


                                            {widget.subtitle && widget.subtitle.length > 0 &&
                                            <Typography variant="h6" component="h3">
                                                {widget.subtitle}
                                            </Typography>
                                            }

                                            {widget.value &&
                                            <Textfit style={{
                                                width: '100%',
                                                height: '100%',
                                                lineHeight: 'normal',
                                                padding: 0,
                                                margin: 0
                                            }}
                                                     min={8} max={400} mode="single" forceSingleModeWidth={false}>
                                                <span className="value__main">{widget.value.main}</span>&nbsp;<span
                                                className="value__additional">{widget.value.additional}</span>
                                            </Textfit>
                                            }

                                            {widget.chartOptions &&
                                            <div className={classes.widgetChartContainer}>
                                                <HighchartsReactFunctional
                                                    constructorType={widget.chartOptions.chart.type === 'areasplinerange' ? 'stockChart' : 'chart'}
                                                    options={widget.chartOptions}
                                                    immutable={false}
                                                    allowChartUpdate={true}
                                                />
                                            </div>

                                            }
                                        </CardContent>
                                    </Card>
                                )
                            })}
                        </ResponsiveGridLayout>

                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={widgetIdToDelete != null}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Eintrag löschen?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sind Sie sicher, dass sie dieses Widget löschen möchten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={deleteWidget} color="primary" autoFocus>
                        Widget löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = state => {
    const dashboardSelectableWidgets = state.dashboard.selectableWidgets.allIds.reduce((filtered, widgetId) => {
        if (state.dashboard.widgets.allIds.length === 0 || (state.dashboard.widgets.allIds.length > 0 && !state.dashboard.widgets.allIds.includes(widgetId))) {
            filtered.push({
                ...state.dashboard.selectableWidgets.byId[widgetId]
            })
        }
        return filtered
    }, [])

    return {
        dashboardEditMode: state.dashboard.editMode,
        dashboardWidgets: state.dashboard.widgets,
        dashboardLayouts: state.dashboard.layouts,
        dashboardSelectableWidgets,
        dashboardAutoReloadEnabled: state.dashboard.autoReloadEnabled
    }
}

export default connect(mapStateToProps)(Dashboard)
