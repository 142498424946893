import React from 'react'
import {makeStyles} from '@material-ui/styles'
import {Avatar, Typography} from '@material-ui/core'
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import authActions from "../../../../../store/auth/actions"
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1),
        textAlign: 'center'
    }
}))

const Profile = props => {
    const {dispatch, history} = props

    const classes = useStyles()

    const user = {
        name: props.username,
        bio: 'emonit Kunde',
        avatar: props.avatar
    }

    const [anchorEl, setAnchorEl] = React.useState(null)

    const onLogoutClick = () => {
        dispatch(authActions.logout())
    }

    const onGotoPath = (path) => {
        handleClose()

        if (history.location.pathname !== path) {
            history.push(path)
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={classes.root}>
            <Avatar
                alt={user.name}
                className={classes.avatar}
                src={user.avatar}
                onClick={handleClick}
                color="primary"
                style={{cursor: 'pointer'}}
            >
                <AccountCircleIcon/>
            </Avatar>
            <Typography
                className={classes.name}
                variant="h4"
            >
                {user.name}
            </Typography>
            <Typography variant="body2">{user.bio}</Typography>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: -50,
                    horizontal: 'center'
                }}
            >
                <MenuItem onClick={() => onGotoPath('/account')} disabled={history.location.pathname === '/account'}>Mein
                    Konto</MenuItem>
                <MenuItem onClick={onLogoutClick} style={{color: '#ff0000'}}>Ausloggen</MenuItem>
            </Menu>
        </div>
    )
}

export default Profile
