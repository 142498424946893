import Service from "./Service"

import {defaultChartOptions as graphDefaults} from "../data/dashboard_defaults/graph"
import {defaultChartOptions as sparklineDefaults} from "../data/dashboard_defaults/sparkline"
import {defaultChartOptions as pieDefaults} from "../data/dashboard_defaults/pie"
import {defaultChartOptions as gaugeDefaults} from "../data/dashboard_defaults/gauge"
import {defaultChartOptions as bulletDefaults} from "../data/dashboard_defaults/bullet"

const dashboardDefaults = {
    graph: graphDefaults,
    sparkline: sparklineDefaults,
    pie: pieDefaults,
    gauge: gaugeDefaults,
    bullet: bulletDefaults
}

const getRealEstates = config => {
    const realEstates = {byId: {}, allIds: []}

    if(config.liegenschaften === null || config.liegenschaften === undefined){
        return realEstates
    }

    for (let i = 0; i < config.liegenschaften.length; i++) {
        const liegenschaft = config.liegenschaften[i]
        if (!realEstates.allIds.includes(liegenschaft.id)) {
            realEstates.allIds.push(liegenschaft.id)
            realEstates.byId[liegenschaft.id] = {
                id: liegenschaft.id,
                title: liegenschaft.title,
                extendedTitle: "Liegenschaft: " + liegenschaft.title,
                type: "liegenschaft",
                parent: null
            }

            for (let j = 0; j < liegenschaft['gebäude'].length; j++) {
                const gebaeude = liegenschaft["gebäude"][j]
                if (!realEstates.allIds.includes(gebaeude.id)) {
                    realEstates.allIds.push(gebaeude.id)
                    realEstates.byId[gebaeude.id] = {
                        id: gebaeude.id,
                        title: gebaeude.title,
                        extendedTitle: "Gebäude: " + gebaeude.title,
                        type: "gebauede",
                        parent: liegenschaft.id
                    }
                }
            }
        }
    }

    return realEstates
}

const getSelectableWidgets = (config, realEstates) => {
    const possibleWidgets = {byId: {}, allIds: []}

    for (let i = 0; i < config.widgets.length; i++) {
        const widgetGroup = config.widgets[i]
        for (let j = 0; j < widgetGroup.items.length; j++) {
            const widget = widgetGroup.items[j]
            const typeTitle = widget.type.charAt(0).toUpperCase() + widget.type.slice(1)

            if (possibleWidgets.allIds.includes(widget.id)) {
                continue
            }

            possibleWidgets.allIds.push(widget.id)

            const realEstatePath = getWidgetRealEstatePath(widgetGroup.targetId, realEstates)
            const textArray = [
                ...realEstatePath,
                widgetGroup.description
            ]

            let targetTitle = "Global"
            if (widgetGroup.targetId !== null && realEstates.allIds.includes(widgetGroup.targetId)) {
                targetTitle = realEstates.byId[widgetGroup.targetId].extendedTitle
            }

            possibleWidgets.byId[widget.id] = {
                ...widget,
                realEstatePath,
                pathText: realEstatePath.join(" | "),
                text: textArray.join(" | "),
                description: widgetGroup.description + '  (' + typeTitle + ')',
                targetId: widgetGroup.targetId,
                targetTitle
            }
        }
    }

    return possibleWidgets
}

const getWidgetRealEstatePath = (targetId, realEstates) => {
    const path = []
    if (targetId !== null && realEstates.allIds.includes(targetId)) {
        const parentPath = getWidgetRealEstatePath(realEstates.byId[targetId].parent, realEstates)
        if (parentPath.length > 0) {
            for (let i = 0; i < parentPath.length; i++) {
                path.push(parentPath[i])
            }
        }
        path.push(realEstates.byId[targetId].title)
    }
    return path
}

const getDefaultChartoptions = type => {
    return dashboardDefaults[type] ? dashboardDefaults[type] : {}
}

export default class DashboardService extends Service {

    getRealEstatesAndSelectableWidgetsFromConfig = config => {
        const realEstates = getRealEstates(config)
        const selectableWidgets = getSelectableWidgets(config, realEstates)

        return {realEstates, selectableWidgets}
    }

    getDefaultWidgetChartoptions = currWidget => {
        const widget = {
            ...currWidget,
            chartOptions: {}
        }

        if (widget.type !== 'text') {
            widget.chartOptions = getDefaultChartoptions(widget.type)
        }

        return widget
    }
}
