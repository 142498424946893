import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/styles'
import {AppBar, Toolbar, Hidden, IconButton} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import logo from "../../../assets/images/logos/emonit_weiss.png"

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none'
    },
    flexGrow: {
        flexGrow: 1
    }
}))

const Topbar = props => {
    const {className, onSidebarOpen, ...rest} = props

    const classes = useStyles()

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Toolbar>
                <RouterLink to="/">
                    <img
                        alt="Logo"
                        style={{maxWidth: 139}}
                        src={logo}

                    />
                </RouterLink>
                <div className={classes.flexGrow}/>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onSidebarOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

export default Topbar
