import React from 'react'
import {makeStyles} from '@material-ui/styles'
import {Divider, Drawer} from '@material-ui/core'

import {connect, useDispatch} from "react-redux"
import Profile from "./components/Profile"
import SidebarNav from "./components/SidebarNav"
import {withRouter} from 'react-router-dom'

const capitalize = s => {
    return s[0].toUpperCase() + s.slice(1)
}

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 320,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)'
        }
        // '& .MuiSvgIcon-root': {
        //     width: '.9em',
        //     height: '.9em'
        // }
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        marginTop: 5,
        marginBottom: 0
    },
    nav: {
        marginBottom: theme.spacing(2)
    }
}))
const Sidebar = props => {
    const dispatch = useDispatch()

    const {
        open,
        variant,
        onClose,
        history,
        dashboardNavigation,
        energyNavigation,
        ratesNavigation,
        measurementsNavigation,
        messkonzeptNavigation
    } = props

    const classes = useStyles()

    const navigationGroups = {
        dashboardNavigation,
        energyNavigation,
        ratesNavigation,
        measurementsNavigation,
        messkonzeptNavigation
    }

    return (
        <Drawer
            anchor="left"
            classes={{paper: classes.drawer}}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div className={classes.root}>
                <Profile username={props.authUserName} avatar={props.authProfileImage} dispatch={dispatch} history={history}/>
                <Divider className={classes.divider}/>
                <SidebarNav
                    className={classes.nav}
                    navigationGroups={navigationGroups}
                    dispatch={dispatch}
                />
            </div>
        </Drawer>
    )
}

const mapStateToProps = state => {
    // dashboard navigation
    const dashboardNavigation = []
    dashboardNavigation.push({
        title: 'Dashboard'
    })

    // energy navigation
    const energyNavigation = []
    if (state.energy.categories.allIds.length) {
        energyNavigation.push({
            title: "Projekt",
            items: state.energy.categories.allIds.map((categoryId) => {
                return {
                    ...state.energy.categories.byId[categoryId],
                    children: state.energy.categories.byId[categoryId].charts.map((chartId) => {
                        return {
                            id: state.energy.charts.byId[chartId].id,
                            title: state.energy.charts.byId[chartId].title,
                            visible: state.energy.charts.byId[chartId].visible
                        }
                    })
                }
            })
        })
    }

    // rates navigation
    const ratesNavigation = []
    const ratesPages = []

    for (const key in state.app.settings.tarife) {
        if (state.app.settings.tarife[key] === true) {
            ratesPages.push({
                id: 'rates_' + key,
                title: capitalize(key),
                href: '/tarife/' + key
            })
        }
    }

    ratesNavigation.push({
        title: "Tarife",
        items: ratesPages
    })

    // measurements navigation
    const measurementsNavigation = []

    measurementsNavigation.push({
        title: "Messwerte",
        items: state.measurements.navigation
    })

    // messkonzept navigation
    const messkonzeptNavigation = []
    const messkonzeptPages = []

    if(state.app.settings.messkonzept !== null) {
        messkonzeptNavigation.push({
            title: 'Messkonzept',
            href: state.app.settings.messkonzept,
            target: '_blank'
        })
    }



    return {
        dashboardNavigation,
        energyNavigation,
        ratesNavigation,
        measurementsNavigation,
        messkonzeptNavigation,
        authUserName: state.auth.userName,
        authProfileImage: state.auth.profileImage,
    }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
