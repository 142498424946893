import React from 'react'
import {makeStyles} from '@material-ui/styles'
import {Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}))

const Footer = (props) => {
    const classes = useStyles()
    const date = new Date()

    return (
        <div
            className={classes.root}
        >
            {props.showFooterText &&
            <Typography variant="body1">
                {' emonit GmbH '}&copy; {date.getFullYear()} - {'Version: ' + process.env.REACT_APP_VERSION}
            </Typography>
            }
        </div>
    )
}

export default Footer
