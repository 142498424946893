import React, {useEffect} from 'react'
import {makeStyles} from '@material-ui/styles'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import {Button, Grid} from "@material-ui/core"
import {Form, Formik} from "formik"
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ratesActions from "../store/rates/actions"
import {connect, useDispatch} from "react-redux"
import AddIcon from '@material-ui/icons/Add'
import Fab from "@material-ui/core/Fab"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContentText from "@material-ui/core/DialogContentText"
import RatesTable from "../components/RatesTable"
import RatesServiceFactory from "../services/RatesServiceFactory"
import {useParams} from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    divider: {
        margin: '30px 0'
    },
    formSection: {
        marginBottom: 16
    },
    formSectionWithBg: {
        marginBottom: 16,
        backgroundColor: '#f6f6f6'
    },
    tableContainer: {
        minHeight: 100,
        maxHeight: 700
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: theme.spacing(3),
        bottom: theme.spacing(3),
        left: 'auto',
        position: 'fixed'
    }
}))

const ratesServiceFactory = new RatesServiceFactory()

const Rates = props => {
    const classes = useStyles()
    const {type} = useParams()
    const ratesService = ratesServiceFactory.getRateServiceByName(type)
    const typeId = ratesService.getTypeId()

    const ratesObj = typeId in props.rates.data ? props.rates.data[typeId] : null

    const initialFormValues = ratesService ? ratesService.getInitialFormValues() : null
    const pageTitle = ratesService ? ratesService.getPageTitle() : null
    const onChangeFormField = ratesService ? ratesService.getFormFieldChangeHandler() : null

    const dispatch = useDispatch()
    const [formValues, setFormValues] = React.useState(initialFormValues)
    const [deleteDialogVisible, setDeleteDialogVisible] = React.useState(false)
    const [idToDelete, setIdToDelete] = React.useState(null)
    const [dateToDelete, setDateToDelete] = React.useState(null)

    useEffect(() => {
        if (!ratesService || (typeId in props.appSettingsTarife && props.appSettingsTarife[typeId] === false)) {
            props.history.push('/not-found')
        }
    }, [ratesService, props.history, props.appSettingsTarife, typeId])

    useEffect(() => {
        dispatch(ratesActions.fetchRates(typeId))
    }, [typeId, dispatch])


    const handleCloseDeleteDialog = () => {
        setIdToDelete(null)
        setDateToDelete(null)
        setDeleteDialogVisible(false)
    }

    const handleDeleteRow = () => {
        const id = idToDelete
        setIdToDelete(null)
        setDateToDelete(null)
        dispatch(ratesActions.deleteFormData(typeId, id))
        handleCloseDeleteDialog()
    }

    const handleClose = () => {
        dispatch(ratesActions.showForm(false))
    }

    const openNewDataForm = () => {
        setFormValues(initialFormValues)
        dispatch(ratesActions.showForm(true))
    }

    const editRowHandler = row => {
        setFormValues(row.rawData)
        dispatch(ratesActions.showForm(true))
    }

    const deleteRowHandler = row => {
        setIdToDelete(row.rawData.id)
        setDateToDelete(row.date)
        setDeleteDialogVisible(true)
    }

    const formConfig = {
        validationSchema: ratesService ? ratesService.getFormValidationSchema() : null,
        onSubmit: (values) => {
            if (values.id !== null) {
                dispatch(ratesActions.updateFormData(typeId, values))
            } else {
                dispatch(ratesActions.createFormData(typeId, values))
            }
        }
    }

    const FormFieldsTagName = ratesService.getFormFieldsComponent()
    const noData = ratesObj === null || (ratesObj.allIds && ratesObj.allIds.length === 0)
    let content = <div>Noch keine Einträge vorhanden. Klicken Sie auf das Plus-Zeichen um Ihren ersten Eintrag zu
        machen.</div>
    if (noData === false) {
        content = (
            <TableContainer component={Paper} className={classes.tableContainer}>
                {
                    props.rates.loading === true ? <LinearProgress/> :
                        <RatesTable
                            type={typeId}
                            ratesObj={ratesObj}
                            priceMode={props.rates.priceMode}
                            editRowHandler={editRowHandler}
                            deleteRowHandler={deleteRowHandler}
                        />
                }
            </TableContainer>
        )
    }


    return (
        <div className={classes.root}>
            <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={12}>
                    <h1>{pageTitle}</h1>
                </Grid>
                {noData ? null :
                    <Grid item xs={12}>
                        <ToggleButtonGroup color="secondary" size="small" value={props.rates.priceMode} exclusive
                                           onChange={(event, value) => {
                                               if (value !== null) {
                                                   dispatch(ratesActions.setPriceMode(value))
                                               }
                                           }}>
                            <ToggleButton key={1} value={'net'} title="Nettopreise anzeigen">Netto</ToggleButton>
                            <ToggleButton key={2} value={'gross'} title="Bruttopreise anzeigen">Brutto</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                }
                <Grid item xs={12}>
                    {content}
                </Grid>
            </Grid>
            <Dialog open={props.rates.showForm} onClose={handleClose} aria-labelledby="form-dialog-title "
                    maxWidth="md"
                    fullWidth={true}>
                <DialogTitle id="form-dialog-title">{ratesService.getTitle()}tarif bearbeiten</DialogTitle>
                <DialogContent dividers={true}>
                    <Formik
                        initialValues={formValues}
                        validationSchema={formConfig.validationSchema}
                        onSubmit={formConfig.onSubmit}
                        enableReinitialize
                        render={({values, setFieldValue}) => (
                            <Form>
                                <FormFieldsTagName classes={classes} values={values}
                                                   onChangeFormField={onChangeFormField}
                                                   setFieldValue={setFieldValue}
                                                   formValues={formValues}
                                                   setFormValues={setFormValues}
                                />
                                <Grid container spacing={3} style={{marginTop: 16}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        style={{marginRight: 15}}
                                        disabled={false}
                                    >
                                        Speichern
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        type="button"
                                        onClick={handleClose}
                                    >
                                        Abbrechen
                                    </Button>
                                </Grid>
                            </Form>
                        )}
                    >
                    </Formik>
                </DialogContent>
            </Dialog>
            <Dialog
                open={deleteDialogVisible}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Eintrag löschen?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sind Sie sicher, dass sie den Eintrag vom {dateToDelete} löschen möchten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleDeleteRow} color="primary" autoFocus>
                        Eintrag löschen
                    </Button>
                </DialogActions>
            </Dialog>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={openNewDataForm}>
                <AddIcon/>
            </Fab>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        rates: state.rates,
        appSettingsTarife: state.app.settings.tarife
    }
}

export default connect(mapStateToProps)(Rates)
