import React, {useState} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {makeStyles, useTheme} from '@material-ui/styles'
import {useMediaQuery} from '@material-ui/core'

import Footer from "./components/Footer"
import Sidebar from "./components/Sidebar/Sidebar"
import Topbar from "./components/Topbar"

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        }
    },
    shiftContent: {
        paddingLeft: 320
    },
    content: {
        height: '100%'
    }
}));

const Main = props => {
    const {children, useMinimalLayout, isProtected, showFooterText} = props

    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop && useMinimalLayout !== true
            })}
        >
            <Topbar onSidebarOpen={handleSidebarOpen}/>
            {
                useMinimalLayout === true ? null :
                    <Sidebar
                        onClose={handleSidebarClose}
                        open={shouldOpenSidebar}
                        variant={isDesktop ? 'persistent' : 'temporary'}
                    />
            }
            <main className={classes.content}>
                {children}
                {useMinimalLayout === true ? null : <Footer showFooterText={showFooterText}/>}
            </main>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
