import {createAction} from "@reduxjs/toolkit"
import MeasurementsService from "../../services/MeasurementsService"
import appActions from "../app/actions"
import http from "../../axios"
import NavigationService from "../../services/NavigationService"

const navigationService = new NavigationService()
const measurementsService = new MeasurementsService()

const fetchMeasurements = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchMeasurementsStart())

            return http.get('/messwerte')
                .then(response => {
                    if (response.status === 200) {
                        dispatch(fetchMeasurementsSuccess(response.data))
                    }
                    return true
                })
        } catch (err) {
            dispatch(fetchMeasurementsFailure(err.toString()))
            return false
        }
    }
}

const fetchMeasurementsStart = createAction('measurements/fetchMeasurementsStart')

const fetchMeasurementsSuccess = createAction('measurements/fetchMeasurementsSuccess', function prepare(measurementsData) {
    return {
        payload: {
            measurementsData: measurementsData.data
        }
    }
})

const fetchMeasurementsFailure = createAction('measurements/fetchMeasurementsFailure', function prepare(error) {
    return {
        payload: {
            error
        }
    }
})

const fetchSeriesData = () => {
    return async (dispatch, getState) => {
        // start
        dispatch(fetchSeriesDataStart())

        try {
            const state = getState()
            const dateMeasurements = state.app.settings.dateMeasurements
            const idsOfSeriesFetched = []

            const functionWithPromise = async item => {
                let itemData = []
                idsOfSeriesFetched.push(item.id)
                await http.get(item.dataUrl + '&start=' + dateMeasurements.start + '&end=' + dateMeasurements.end)
                    .then((response) => {
                        itemData = response.data
                    })
                return Promise.resolve(measurementsService.getUpdatedSeriesItem(item, itemData, dispatch))
            }

            const anAsyncFunction = async item => {
                return await functionWithPromise(item)
            }

            const fetchData = async (series) => {
                return Promise.all(series.map(seriesItem => {
                    return anAsyncFunction(seriesItem)
                }))
            }

            // get all series we want to fetch
            let finalSeriesToFetch = state.measurements.series.idsToLoad.map(seriesId => {
                const indexOf = state.measurements.series.allIds.indexOf(seriesId)
                return state.measurements.chartOptions.series[indexOf]
            })

            fetchData(finalSeriesToFetch).then(series => {
                dispatch(fetchSeriesDataSuccess(series, idsOfSeriesFetched))
            })

        } catch (err) {
            console.log(err.toString())
            dispatch(fetchSeriesDataFailure(err.toString()))
        }
    }
}

const fetchSeriesDataStart = createAction('measurements/fetchSeriesDataStart', function prepare() {
    return {
        payload: {}
    }
})

const fetchSeriesDataSuccess = createAction('measurements/fetchSeriesDataSuccess', function prepare(series, idsOfSeriesFetched) {
    return {
        payload: {
            series,
            idsOfSeriesFetched
        }
    }
})

const fetchSeriesDataFailure = createAction('measurements/fetchSeriesDataFailure', function prepare(error) {
    return {
        payload: {
            error
        }
    }
})

const patchSeries = (seriesId, patchObj) => {
    return async dispatch => {
        try {
            http.patch(`/messwerte/${seriesId}`, {...patchObj, id: seriesId})
                .then((response) => {
                    dispatch(patchSeriesSuccess(seriesId, patchObj))
                })
        } catch (err) {
        }
    }
}

const patchSeriesByIndex = (seriesIndex, patchObj) => {
    return async (dispatch, getState) => {
        const foundSeriesId = getState().measurements.series.allIds[seriesIndex]
        try {
            if (foundSeriesId) {
                dispatch(patchSeries(foundSeriesId, patchObj))
            }
        } catch (e) {
        }
    }
}

const patchSeriesSuccess = createAction('measurements/patchSeriesSuccess', function prepare(id, data) {
    return {
        payload: {
            id,
            data
        }
    }
})

const patchSeriesLoader = (seriesLoader) => {

    const settingsObj = {
        dateMeasurements: {
            min: seriesLoader.startDate,
            max: seriesLoader.endDate
        }
    }

    return async dispatch => {
        try {
            dispatch(appActions.patchSettings(settingsObj))
        } catch (e) {

        }
        dispatch(updateSeriesLoader(seriesLoader))
    }
}

const updateSeriesLoader = createAction('measurements/updateSeriesLoader', function prepare(seriesLoader) {
    return {
        payload: {
            seriesLoader
        }
    }
})

const setNavigationSectionAreSubItemsVisible = createAction('measurements/setNavigationSectionAreSubItemsVisible', function prepare(sectionId, areSubItemsVisible) {
    // patchNavigation(categoryId, {areSubItemsVisible}) // TODO
    return {
        payload: {
            sectionId,
            areSubItemsVisible
        }
    }
})

const setNavigationSubcategoryAreSubItemsVisible = createAction('measurements/setNavigationSubcategoryAreSubItemsVisible', function prepare(sectionId, subcategoryId, areSubItemsVisible) {
    // patchNavigation(categoryId, {areSubItemsVisible}) // TODO
    return {
        payload: {
            sectionId,
            subcategoryId,
            areSubItemsVisible
        }
    }
})

const patchMultipleSeries = (patchObj) => {
    try {
        http.patch(`/messwerte/charts`, {data: patchObj})
    } catch (err) {
    }
}

const patchSingleSeries = (seriesId, patchObj) => {
    try {
        http.patch(`/messwerte/${seriesId}`, {...patchObj, id: seriesId})
            .then((response) => {
            })
    } catch (err) {

    }
}

const setSubItemsCheckboxValue = createAction('measurements/setSubItemsCheckboxValue', function prepare(parentId, parentCurrentCheckboxValue, children) {

    const {
        parentUpdatedCheckboxValue,
        updatedChildren
    } = navigationService.getUpdatedCheckboxValueAndChildren(parentCurrentCheckboxValue, children)

    patchMultipleSeries(updatedChildren)

    // updatedChildren.forEach(series => {
    //     patchSingleSeries(series.id, {visible: series.visible})
    // })

    return {
        payload: {
            parentId,
            parentUpdatedCheckboxValue,
            updatedChildren
        }
    }


})

const reset = createAction('measurements/reset')

export default {
    reset,
    fetchMeasurements,
    fetchMeasurementsStart,
    fetchMeasurementsSuccess,
    fetchMeasurementsFailure,
    fetchSeriesData,
    fetchSeriesDataStart,
    fetchSeriesDataSuccess,
    fetchSeriesDataFailure,
    patchSeries,
    patchSeriesByIndex,
    patchSeriesSuccess,
    patchSeriesLoader,
    updateSeriesLoader,
    setNavigationSectionAreSubItemsVisible,
    setNavigationSubcategoryAreSubItemsVisible,
    setSubItemsCheckboxValue
}
