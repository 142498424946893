import {createReducer} from "@reduxjs/toolkit"
import actions from './actions'
import EnergyService from "../../services/EnergyService"
import NavigationService from "../../services/NavigationService"

const navigationService = new NavigationService()
const energyService = new EnergyService()

const initialState = {
    categories: {
        loading: true,
        error: '',
        byId: {},
        allIds: []
    },
    charts: {byId: {}, allIds: []},
    series: {byId: {}, allIds: []},
    seriesUpdateCounter: 1
}

export default createReducer(initialState, {
    [actions.reset]: state => {
        state.categories = {...initialState.categories}
        state.charts = {...initialState.charts}
        state.series = {...initialState.series}
        state.seriesUpdateCounter = initialState.seriesUpdateCounter
    },
    [actions.setChartVisibility]: (state, action) => {
        const chart = state.charts.byId[action.payload.chartId]
        chart.visible = action.payload.visible

        const category = state.categories.byId[chart.categoryId]
        if (category) {
            const charts = category.charts.map((chartId) => {
                return state.charts.byId[chartId]
            })
            category.subItemsCheckboxValue = navigationService.getSubItemsCheckboxValue(charts)
        }
    },
    [actions.setSubItemsCheckboxValue]: (state, action) => {
        state.categories.byId[action.payload.categoryId].subItemsCheckboxValue = action.payload.parentUpdatedCheckboxValue
        for (let i = 0; i < action.payload.updatedChildren.length; i++) {
            state.charts.byId[action.payload.updatedChildren[i].id].visible = action.payload.updatedChildren[i].visible
        }
    },
    [actions.setCategoryAreSubItemsVisible]: (state, action) => {
        state.categories.byId[action.payload.categoryId].areSubItemsVisible = action.payload.areSubItemsVisible
    },
    [actions.fetchCategoriesStart]: state => {
        state.loadingCategories = true
    },
    [actions.fetchCategoriesSuccess]: (state, action) => {
        const {categories, charts, series} = energyService.getEnergyDataNormalized(action.payload.energyData)
        state.categories = {
            ...categories,
            loading: false,
            error: ''
        }
        state.charts = charts
        state.series = series
    },
    [actions.fetchCategoriesFailure]: (state, action) => {
        state.categories.loading = false
        state.categories.error = action.payload.error
    },
    [actions.fetchSeriesDataStart]: (state, action) => {
        state.charts.byId[action.payload.chartId].seriesDataFetched = false
        state.charts.byId[action.payload.chartId].loading = true
    },
    [actions.fetchSeriesDataSuccess]: (state, action) => {
        for (let i = 0; i <= action.payload.series.length; i++) {
            if (action.payload.series[i]) {
                state.series.byId[action.payload.series[i].id] = {
                    ...action.payload.series[i],
                    visible: true
                }
            }
        }

        if (state.charts.byId[action.payload.chartId] !== null) {
            state.charts.byId[action.payload.chartId].seriesDataFetched = true
            state.charts.byId[action.payload.chartId].loading = false
        }
    },
    [actions.fetchSeriesDataFailure]: (state, action) => {
        state.charts.byId[action.payload.chartId].seriesDataFetched = true
        state.charts.byId[action.payload.chartId].loading = false
        // TODO log action.payload.error
    },
    [actions.getUpdatedSeriesData]: (state, action) => {
        // for (let i = 0; i <= action.payload.seriesIds.length; i++) {
        //     const seriesId = action.payload.seriesIds[i]
        //     state.series.byId[seriesId] = energyService.getUpdatedSeriesItem(state.series.byId[seriesId], null, action.payload.appDataGroupingUnit)
        // }
    },
    [actions.getUpdatedSeriesDataStart]: (state, action) => {
        state.charts.byId[action.payload.chartId].loading = true
    },
    [actions.getUpdatedSeriesDataFinish]: (state, action) => {
        state.charts.byId[action.payload.chartId].loading = false
    },
    [actions.setSeriesItemVisibility]: (state, action) => {
        state.series.byId[action.payload.id].visible = action.payload.visible
        state.seriesUpdateCounter++
    }
})
