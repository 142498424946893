import moment from "moment";
import RatesService from "../RatesService"
import * as Yup from "yup"
import GasFormFields from "../../components/Rates/FormFields/GasFormFields"
import Texts from "../../Texts";

export default class GasRatesService extends RatesService {

    getTypeId = () => 'gas'
    getTitle = () => 'Gas'
    getFormFieldsComponent = () => GasFormFields

    getTableHeaderColumns = (ratesObj, priceMode) => {
        return [
            this.getTableCell(1, 'Gültig ab', null, 'inherit'),
            this.getTableCell(2, 'Grundpreis/Monat', null, 'right'),
            this.getTableCell(3, 'Grundpreis/Jahr', null, 'right'),
            this.getTableCell(5, 'Arbeitspreis', null, 'right'),
            this.getTableCell(6, 'Monatsabschlag', null, 'right'),
            this.getTableCell(7, 'Zustandszahl', null, 'right'),
            this.getTableCell(8, 'Brennwert', null, 'right')
        ]
    }

    getAdditionalTableDataBodyRows = (row, data, ratesObj, priceMode) => {
        let isInheritable = true
        row.columns.push(this.getTableBodyRowColumn(row, data, 'basePriceMonth', '€', isInheritable, 'price', priceMode))
        row.columns.push(this.getTableBodyRowColumn(row, data, 'basePriceYear', '€', isInheritable, 'price', priceMode))
        row.columns.push(this.getTableBodyRowColumn(row, data, 'workPrice', '€/kWh', isInheritable, 'price', priceMode))
        row.columns.push(this.getTableBodyRowColumn(row, data, 'monthlyPrice', '€', isInheritable, 'price', priceMode))
        row.columns.push(this.getTableBodyRowColumn(row, data, 'stateNumber', null, isInheritable))
        row.columns.push(this.getTableBodyRowColumn(row, data, 'heatingValue', null, isInheritable))
    }

    getInitialFormValues = () => {
        return {
            id: null,
            date: moment().format('YYYY-MM-DD'),
            vat: 19,
            basePriceMonthNet: null,
            basePriceMonthGross: null,
            basePriceYearNet: null,
            basePriceYearGross: null,
            workPriceInputInEuro: false,
            workPriceNet: null,
            workPriceGross: null,
            monthlyPriceNet: null,
            monthlyPriceGross: null,
            stateNumber: null,
            heatingValue: null
        }
    }

    getFormValidationSchema = () => {
        return Yup.object(
            {
                id: Yup.string()
                    .nullable(),
                date: Yup.string()
                    .required(Texts.form.error.required),
                vat: Yup.number().integer()
                    .required(Texts.form.error.required),
                basePriceMonthNet: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,2})?\s*$/)
                    .nullable(),
                basePriceMonthGross: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,2})?\s*$/)
                    .nullable(),
                basePriceYearNet: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,2})?\s*$/)
                    .nullable(),
                basePriceYearGross: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,2})?\s*$/)
                    .nullable(),
                workPriceInputInEuro: Yup.boolean(),
                workPriceNet: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,4})?\s*$/)
                    .nullable(),
                workPriceGross: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,4})?\s*$/)
                    .nullable(),
                monthlyPriceNet: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,2})?\s*$/)
                    .nullable(),
                monthlyPriceGross: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,2})?\s*$/)
                    .nullable(),
                stateNumber: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,4})?\s*$/)
                    .nullable(),
                heatingValue: Yup.string().matches(/^\s*(?=.*[1-9])\d*(?:,\d{1,4})?\s*$/)
                    .nullable()
            }
        )
    }
}
