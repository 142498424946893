import Service from "./Service"
import moment from "moment"

export default class RatesService extends Service {

    inheritableRowValues = {}
    inheritableLastId = null

    getFloatFields = () => [
        "basePriceMonthNet",
        "basePriceMonthGross",
        "basePriceYearNet",
        "basePriceYearGross",
        "monthlyPriceNet",
        "monthlyPriceGross",
        "workPriceNet",
        "workPriceGross",
        "stateNumber",
        "heatingValue"
    ]

    getFinalFormData = data => {
        const finalData = {...data}

        const floatFields = this.getFloatFields()

        for (const fieldName of floatFields) {
            switch (fieldName) {
                case "basePriceMonthNet":
                case "basePriceMonthGross":
                case "basePriceYearNet":
                case "basePriceYearGross":
                case "monthlyPriceNet":
                case "monthlyPriceGross":
                case "workPriceNet":
                case "workPriceGross":

                // gas
                case "stateNumber":
                case "heatingValue":
                    if (fieldName in finalData && finalData[fieldName] !== null) {
                        finalData[fieldName] = this.germanPriceToFloat(finalData[fieldName])
                    }
                    break
                default:
            }
        }

        if (finalData.workPriceInputInEuro === false && finalData.workPriceNet !== null) {
            finalData.workPriceNet = this.getPriceFormatted(this.cent2Euro(finalData.workPriceNet), 4)
            finalData.workPriceGross = this.getPriceFormatted(this.cent2Euro(finalData.workPriceGross), 4)
        }

        return finalData
    }

    euro2Cent = euro => euro * 100
    cent2Euro = cent => cent / 100

    getRatesDataNormalized = (ratesData) => {
        const normalizedObject = {
            allIds: [],
            byId: {}
        }

        for (const currData of ratesData) {

            const currentRate = {
                ...currData
            }

            if (currData.workPriceInputInEuro !== undefined && currData.workPriceInputInEuro !== null && currData.workPriceInputInEuro !== "" && currData.workPriceInputInEuro === false) { // Kunde hat den Preis in Cent/kWh eingegeben...Preise werden aber alle in Euro an den Server gesendet => also umwandeln
                if (currData.workPriceNet !== undefined && currData.workPriceNet !== null && currData.workPriceGross !== undefined && currData.workPriceGross !== null) {
                    currentRate.workPriceNet = this.getPriceFormatted(this.euro2Cent(currentRate.workPriceNet), 4)
                    currentRate.workPriceGross = this.getPriceFormatted(this.euro2Cent(currentRate.workPriceGross), 4)
                }
            }

            for (const fieldName of this.getFloatFields()) {
                switch (fieldName) {
                    case "basePriceMonthNet":
                    case "basePriceMonthGross":
                    case "basePriceYearNet":
                    case "basePriceYearGross":
                    case "monthlyPriceNet":
                    case "monthlyPriceGross":
                    case "workPriceNet":
                    case "workPriceGross":

                    // gas
                    case "stateNumber":
                    case "heatingValue":
                        if (fieldName in currentRate && currentRate[fieldName] !== null) {
                            currentRate[fieldName] = this.floatToGerman(currentRate[fieldName])
                        }
                        break
                    default:
                }
            }

            normalizedObject.allIds.push(currData.id)
            normalizedObject.byId[currData.id] = currentRate
        }

        return normalizedObject
    }

    getInitialFormValues = () => {
        return {
            id: null
        }
    }

    getTypeId = () => "rate"
    getTitle = () => "[Titel]"
    getPageTitle = () => "Tarif - " + this.getTitle()

    getTableBodyRows = (ratesObj, priceMode) => {
        if (ratesObj === null) return []

        this.inheritableRowValues = {}
        this.inheritableLastId = null
        const bodyRows = []

        for (const id of ratesObj.allIds) {
            const data = ratesObj.byId[id]
            const date = moment(data["date"]).format("DD.MM.YYYY")
            const row = {
                id,
                key: id,
                rawData: data,
                date,
                columns: [
                    this.getTableCell(id + "_date", date, null, "inherit")
                ]
            }

            this.getAdditionalTableDataBodyRows(row, data, ratesObj, priceMode)

            bodyRows.push(row)
        }

        return bodyRows.reverse()
    }

    getAdditionalTableDataBodyRows = (row, data, ratesObj, priceMode) => null

    getTableBodyRowColumn = (row, data, fieldName, postContentString = null, isInheritable = false, fieldType = null, priceMode = null) => {
        let initialFieldName = fieldName
        let fieldValueIsPrice = false
        let fieldHasEuroCentSwitch = (fieldName + "InputInEuro" in data)
        let fieldValueIsInEuro = fieldHasEuroCentSwitch && data[fieldName + "InputInEuro"] === true

        if (fieldType === "price" && priceMode !== null && ["net", "gross"].includes(priceMode)) {
            fieldValueIsPrice = true

            const updatedFieldName = fieldName + this.capitalize(priceMode)

            if (updatedFieldName in data) {
                fieldName = updatedFieldName
            }
        }

        let value = data[fieldName]

        if (value !== null && fieldValueIsPrice && fieldHasEuroCentSwitch && fieldValueIsInEuro === false) {
            value = this.cent2Euro(this.germanPriceToFloat(value))
            if (initialFieldName == "workPrice") {
                value = this.floatToGerman(this.getPriceFormatted(value, 4))
            }
        }

        let inherited = false

        if (data[fieldName] === null && this.inheritableLastId !== null) { // no data by customer // for all rows except first
            if (fieldName in this.inheritableRowValues) {
                value = this.inheritableRowValues[fieldName]
                inherited = true
            }
        } else {
            this.inheritableRowValues[fieldName] = value
        }

        this.inheritableLastId = row.id

        return this.getTableCell(row.id + "_" + fieldName, value, postContentString, "right", inherited)
    }

    getFormFieldChangeHandler = () => {
        return (e, formValues, setFormValues) => {
            const updatedFormValues = {}

            this.getDefaultFormFieldChangeHandlerDetails(e, formValues, updatedFormValues)
            this.getFormFieldChangeHandlerDetails(e, formValues, updatedFormValues)

            setFormValues({
                ...formValues,
                ...updatedFormValues
            })
        }
    }

    getFormFieldChangeHandlerDetails = (e, formValues, updatedFormValues) => null


    getBasePriceVariantsFromMonthNet = (updatedFormValues, fieldName, val, vat) => {
        const net = val
        const netYear = val * 12
        if (fieldName !== "basePriceMonthNet") updatedFormValues.basePriceMonthNet = this.floatToGerman(this.getPriceFormatted(net))
        if (fieldName !== "basePriceMonthGross") updatedFormValues.basePriceMonthGross = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(net, vat)))
        if (fieldName !== "basePriceYearNet") updatedFormValues.basePriceYearNet = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(netYear))
        if (fieldName !== "basePriceYearGross") updatedFormValues.basePriceYearGross = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(netYear, vat)))
    }

    germanPriceToFloat = (price) => {
        const floatNumber = parseFloat(price.replace(",", "."))
        return !Number.isNaN(floatNumber) ? floatNumber : null
    }

    getPriceFormatted = (price, decimalsAfterComma = 2) => parseFloat(price).toFixed(decimalsAfterComma)
    floatToGerman = price => {
        const tempPrice = parseFloat(price)
        if (!Number.isNaN(tempPrice)) {
            return tempPrice.toString().replace(".", ",")
        }
    }

    getDefaultFormFieldChangeHandlerDetails = (e, formValues, updatedFormValues) => {
        let val = e.target.value
        let floatVal = null

        switch (e.target.name) {
            case "basePriceMonthNet":
            case "basePriceMonthGross":
            case "basePriceYearNet":
            case "basePriceYearGross":

                floatVal = this.germanPriceToFloat(val)
                updatedFormValues[e.target.name] = val !== null && val.length >= 1 ? val.replace(".", ",") : val
                let monthNetVal = floatVal

                if (e.target.name === "basePriceMonthGross") {
                    monthNetVal = val === "" ? "" : this.getNetPrice(floatVal, formValues["vat"])
                } else if (e.target.name === "basePriceYearNet") {
                    monthNetVal = val === "" ? "" : floatVal / 12
                } else if (e.target.name === "basePriceYearGross") {
                    monthNetVal = val === "" ? "" : this.getNetPrice(floatVal / 12, formValues["vat"])
                }

                this.getBasePriceVariantsFromMonthNet(updatedFormValues, e.target.name, monthNetVal, formValues["vat"])

                break

            case "monthlyPriceNet":
            case "monthlyPriceGross":
                floatVal = this.germanPriceToFloat(val)
                updatedFormValues[e.target.name] = val !== null && val.length >= 1 ? val.replace(".", ",") : val

                if (e.target.name === "monthlyPriceNet") {
                    updatedFormValues.monthlyPriceGross = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(floatVal, formValues["vat"])))
                } else if (e.target.name === "monthlyPriceGross") {
                    updatedFormValues.monthlyPriceNet = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(this.getNetPrice(floatVal, formValues["vat"])))
                }
                break

            case "workPriceNet":
            case "workPriceGross":
                floatVal = this.germanPriceToFloat(val, 4)
                updatedFormValues[e.target.name] = val !== null && val.length >= 1 ? val.replace(".", ",") : val

                if (e.target.name === "workPriceNet") {
                    updatedFormValues.workPriceGross = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(floatVal, formValues["vat"]), 4))
                } else if (e.target.name === "workPriceGross") {
                    updatedFormValues.workPriceNet = val === "" ? "" : this.floatToGerman(this.getPriceFormatted(this.getNetPrice(floatVal, formValues["vat"]), 4))
                }
                break

            case "workPriceInputInEuro":
                updatedFormValues.workPriceInputInEuro = e.target.checked
                if (formValues["workPriceNet"] !== undefined && formValues["workPriceNet"] !== null && formValues["workPriceNet"] !== "") {
                    const netVal = this.germanPriceToFloat(formValues["workPriceNet"])
                    const netValConverted = e.target.checked === true ? this.getPriceFormatted(this.cent2Euro(netVal), 4) : this.getPriceFormatted(this.euro2Cent(netVal), 4)
                    updatedFormValues.workPriceNet = e.target.checked === true ? this.floatToGerman(netValConverted) : this.floatToGerman(netValConverted)
                    updatedFormValues.workPriceGross = this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(netValConverted, formValues["vat"]), 4))
                }
                break

            case "vat":
                updatedFormValues.vat = val

                if (formValues["basePriceMonthNet"] !== undefined && formValues["basePriceMonthNet"] !== null && formValues["basePriceMonthNet"] !== "") {
                    const monthNetValue = this.getPriceFormatted(formValues["basePriceMonthNet"])
                    updatedFormValues.basePriceMonthNet = this.floatToGerman(monthNetValue)
                    this.getBasePriceVariantsFromMonthNet(updatedFormValues, "basePriceNet", monthNetValue, val)
                }

                if (formValues["monthlyPriceNet"] !== undefined && formValues["monthlyPriceNet"] !== null && formValues["monthlyPriceNet"] !== "") {
                    const netVal = this.getPriceFormatted(formValues["monthlyPriceNet"])
                    updatedFormValues.monthlyPriceNet = this.floatToGerman(this.getPriceFormatted(netVal))
                    updatedFormValues.monthlyPriceGross = this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(netVal, val)))
                }

                if (formValues["workPriceNet"] !== undefined && formValues["workPriceNet"] !== null && formValues["workPriceNet"] !== "") {
                    const netVal = this.germanPriceToFloat(formValues["workPriceNet"])
                    updatedFormValues.workPriceNet = this.floatToGerman(this.getPriceFormatted(netVal, 4))
                    updatedFormValues.workPriceGross = this.floatToGerman(this.getPriceFormatted(this.getGrossPrice(netVal, val), 4))
                }
                break

            case "stateNumber":
            case "heatingValue":
                floatVal = this.germanPriceToFloat(val)
                updatedFormValues[e.target.name] = val !== null && val.length >= 1 ? val.replace(".", ",") : val
            default:
                updatedFormValues[e.target.name] = val
        }
    }

    getFormValidationSchema = () => null

    getFormFieldsComponent = () => null

    getUrl = action => {
        switch (action) {
            case "create":
                return "/tarife"
            case "list":
                return "/tarife/" + this.getTypeId()
            case "update":
            case "delete":
                return "/tarife/###ID###"
            default:
                return null
        }
    }

    getTable = (ratesObj, priceMode) => {
        return {
            header: {
                columns: this.getTableHeaderColumns(ratesObj, priceMode)
            },
            body: {
                rows: this.getTableBodyRows(ratesObj, priceMode)
            }
        }
    }

    getTableHeaderColumns = (ratesObj, priceMode) => []

    getTableCell = (key, content = null, postContentString = null, alignment = "inherited", inherited = false) => {
        return {
            key,
            alignment,
            inherited,
            content,
            postContentString: (content !== null && postContentString !== null) ? postContentString : null
        }
    }

    getNetPrice = (priceGross, vat) => priceGross / (1 + this.getVatRate(vat))
    getGrossPrice = (priceNet, vat) => priceNet * (1 + this.getVatRate(vat))
    getVatRate = vat => vat >= 1 ? (vat / 100) : vat

    calcNetGrossPrices = (data, fieldName, priceInclVat, vat) => {
        const fieldNameNet = fieldName + "Net"
        const fieldNameGross = fieldName + "Gross"

        if (fieldName in data) {
            data[fieldName] = parseFloat(data[fieldName])
            data[fieldNameNet] = priceInclVat === false ? data[fieldName] : this.getNetPrice(data[fieldName], vat)
            data[fieldNameGross] = priceInclVat === true ? data[fieldName] : this.getGrossPrice(data[fieldName], vat)
        }
    }

    calcEuroPrices = (data, fieldName, priceInEuro, priceInclVat, vat) => {
        //const fieldNameCent = fieldName + 'Cent'
        const fieldNameEuro = fieldName + "Euro"

        if (fieldName in data) {
            data[fieldName] = parseFloat(data[fieldName])
            // data[fieldNameCent] = priceInEuro === false ? data[fieldName] : this.multiplyPriceBy(data[fieldName], 100) // Client only wants to save the EURO Price
            data[fieldNameEuro] = priceInEuro === true ? data[fieldName] : this.multiplyPriceBy(data[fieldName], (1 / 100))

            this.calcNetGrossPrices(data, fieldNameEuro, priceInclVat, vat)
        }
    }

    capitalize = (s) => {
        if (typeof s !== "string") return ""
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
}
