import React, {useEffect, useMemo} from "react"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Card from "@material-ui/core/Card"
import {makeStyles} from "@material-ui/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import {connect, useDispatch} from "react-redux"
import energyActions from "../../store/energy/actions"
import EnergyService from "../../services/EnergyService"
import appActions from "../../store/app/actions"
import HighchartsReactFunctional from "../HighchartsReactFunctional"
import {highchartsTs2AppTs} from "../../helperfunctions/date"
import _ from "lodash"

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
}))

const energyService = new EnergyService()

const StockChart = props => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {chartId} = props
    const chart = props.energyChartsById[chartId]
    const handleAfterSetExtremes = (value) => {
        dispatch(appActions.updateSettings({
            date: {
                min: highchartsTs2AppTs(value.min),
                max: highchartsTs2AppTs(value.max)
            }
        }))
    }

    useEffect(() => {
        if (chart.visible && chart.seriesDataFetched === false) {
            dispatch(energyActions.fetchSeriesData(chartId))
        }
    }, [chart.visible, chart.seriesDataFetched])

    let loaderContent = <LinearProgress/>


    const singleChartContent = useMemo(() => {
        if (chart.seriesDataFetched === true && chart.loading === false && chart.visible) {

            const chartOptions = _.merge(
                {...energyService.getDefaultStockChartOptions(props.appSettingsDateStart, props.appSettingsDateEnd, props.appSettingsDateMin, props.appSettingsDateMax, handleAfterSetExtremes)},
                chart
            )

            chartOptions.series = chart.series.map((seriesId) => {

                const seriesItem = _.merge(
                    {...props.energySeriesById[seriesId]},
                    {
                        events: {
                            show: () => dispatch(energyActions.setSeriesItemVisibility(seriesId, true)),
                            hide: () => dispatch(energyActions.setSeriesItemVisibility(seriesId, false))
                        }
                    }
                )

                return energyService.getUpdatedSeriesItem(seriesItem, null, props.appSettingsDataGroupingUnit)
            })
            chartOptions.boost = {seriesThreshold: chart.series.length}

            return <HighchartsReactFunctional
                constructorType={chartOptions.series[0].type === 'areasplinerange' ? 'stockChart' : 'chart'}
                options={chartOptions}
                immutable={true}
            />
        }
        return null
    }, [chart.seriesDataFetched, chart.loading, props.appSettingsDataGroupingUnit, props.appSettingsDateMin, props.appSettingsDateMax, props.energySeriesUpdateCounter])


    const content = useMemo(() => {
        if (chart.visible && singleChartContent !== null) {
            return (
                <Card className={classes.paper}>
                    <CardHeader
                        title={props.title}
                    >
                    </CardHeader>
                    <CardContent>
                        {singleChartContent !== null ? singleChartContent : loaderContent}
                    </CardContent>
                </Card>
            )
        }
        return null
    }, [chart.visible, singleChartContent, loaderContent])


    return content
}

const mapStateToProps = state => {
    return {
        appSettingsDateMin: state.app.settings.date.min,
        appSettingsDateMax: state.app.settings.date.max,
        appSettingsDateStart: state.app.settings.date.start,
        appSettingsDateEnd: state.app.settings.date.end,
        appSettingsDataGroupingUnit: state.app.settings.dataGroupingUnit,
        energyChartsById: state.energy.charts.byId,
        energySeriesById: state.energy.series.byId,
        energySeriesUpdateCounter: state.energy.seriesUpdateCounter
    }
}

export default connect(mapStateToProps)(StockChart)
