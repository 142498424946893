import {createAction} from "@reduxjs/toolkit"
import http from "../../axios"
import notificationActions from "../notification/actions"

const fetchConfig = () => {
    return async dispatch => {
        dispatch(fetchConfigStart())

        return http.get("/dashboard/config")
            .then(response => {
                if (response.status === 200) {
                    dispatch(fetchConfigSuccess(response.data.data))
                }
                return true
            }).catch(error => {

                let message = "Fehler beim Laden der Dashboard Konfiguration. Bitte laden Sie die Seite neu um Widgets bearbeiten zu können."

                dispatch(notificationActions.notify("error", message))
                dispatch(fetchConfigFailure())
                return false
            })
    }
}

const fetchConfigStart = createAction("dashboard/fetchConfigStart")

const fetchConfigSuccess = createAction("dashboard/fetchConfigSuccess", function prepare(config) {
    return {
        payload: {
            config
        }
    }
})

const fetchConfigFailure = createAction("dashboard/fetchConfigFailure")

const fetchWidgets = () => {
    return async (dispatch, getState) => {
        dispatch(fetchConfigStart())

        return http.get("/dashboard")
            .then(response => {
                if (response.status === 200) {
                    dispatch(fetchWidgetsSuccess(response.data.data.widgets, response.data.data.layouts))
                }
                return true
            }).catch(error => {

                let message = "Fehler beim Laden der Dashboard Widgets."
                dispatch(notificationActions.notify("error", message))
                dispatch(fetchWidgetsFailure())
                return false
            })
    }
}

const fetchWidgetsStart = createAction("dashboard/fetchWidgetsStart")

const fetchWidgetsSuccess = createAction("dashboard/fetchWidgetsSuccess", function prepare(widgets, layouts) {
    return {
        payload: {
            widgets,
            layouts
        }
    }
})

const fetchWidgetsFailure = createAction("dashboard/fetchWidgetsFailure")

const addWidget = selectedWidget => {
    return async dispatch => {
        dispatch(addWidgetStart())

        return http.post("/dashboard/widget", {id: selectedWidget.id})
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    dispatch(addWidgetSuccess(selectedWidget))
                    dispatch(fetchWidgets())
                }

                return true
            }).catch(error => {

                let message = "Fehler beim Erstellen des Widgets. Bitte versuchen Sie es noch einmal."
                if (error.response && error.response.data.error) {
                    message = error.response.data.error
                }
                dispatch(notificationActions.notify("error", message))
                dispatch(addWidgetFailure())

                return false
            })
    }
}

const addWidgetStart = createAction("dashboard/addWidgetStart")

const addWidgetSuccess = createAction("dashboard/addWidgetSuccess", function prepare(widget) {
    return {
        payload: {
            widget
        }
    }
})

const addWidgetFailure = createAction("dashboard/addWidgetFailure")

const deleteWidget = id => {
    return async dispatch => {
        dispatch(deleteWidgetStart())

        return http.delete("/dashboard/widget/" + id)
            .then(response => {
                if (response.status === 204) {
                    dispatch(deleteWidgetSuccess(id))
                }
                return true
            }).catch(error => {

                let message = "Fehler beim Löschen des Widgets. Bitte versuchen Sie es noch einmal."
                if (error.response && error.response.data.error) {
                    message = error.response.data.error
                }
                dispatch(notificationActions.notify("error", message))
            })
    }
}

const deleteWidgetStart = createAction("dashboard/deleteWidgetStart")

const deleteWidgetSuccess = createAction("dashboard/deleteWidgetSuccess", function prepare(id) {
    return {
        payload: {
            id
        }
    }
})

const patchDashboard = widgets => {
    return async dispatch => {
        dispatch(patchDashboardStart())

        return http.patch("/dashboard", widgets)
            .then(response => {

                if (response.status === 200) {
                    dispatch(patchDashboardSuccess(response.data.data))
                }
                return true
            }).catch(error => {

                let message = "Fehler beim Speichern des Dashboard Layouts."
                if (error.response && error.response.data.error) {
                    message = error.response.data.error
                }
                dispatch(notificationActions.notify("error", message))
                dispatch(patchDashboardFailure())
                return false
            })
    }
}

const patchDashboardStart = createAction("dashboard/patchDashboardStart")

const patchDashboardSuccess = createAction("dashboard/patchDashboardSuccess", function prepare(config) {
    return {
        payload: {
            config
        }
    }
})

const patchDashboardFailure = createAction("dashboard/patchDashboardFailure")

const patchDashboardLayouts = layouts => {
    return async (dispatch, getState) => {
        dispatch(patchDashboardLayoutsStart())
        const state = getState()
        // state.dashboard.widgets.byId


        console.log('PATCH A', layouts)
        for (const [breakpoint, widgetLayouts] of Object.entries(layouts)) {
            console.log(breakpoint)
            widgetLayouts.forEach((widgetLayout, index) => {
                const widget = state.dashboard.widgets.byId[widgetLayout.i]
                console.log(index, widget)
                if (widget) {
                    ['minW', 'minH', 'maxW', 'maxH'].forEach(key => {
                        if (widget[key]) {
                            layouts[breakpoint][index][key] = widget[key]
                            if (key === 'minW' && (layouts[breakpoint][index]['w'] === null || layouts[breakpoint][index]['w'] === undefined || layouts[breakpoint][index]['w'] < widget[key])) {
                                layouts[breakpoint][index]['w'] = widget[key]
                            } else if (key === 'minH' && (layouts[breakpoint][index]['h'] === null || layouts[breakpoint][index]['h'] === undefined || layouts[breakpoint][index]['h'] < widget[key])) {
                                layouts[breakpoint][index]['h'] = widget[key]
                            } else if (key === 'maxW' && layouts[breakpoint][index]['w'] !== null && layouts[breakpoint][index]['w'] !== undefined && layouts[breakpoint][index]['w'] > widget[key]) {
                                layouts[breakpoint][index]['w'] = widget[key]
                            } else if (key === 'maxH' && layouts[breakpoint][index]['h'] !== null && layouts[breakpoint][index]['h'] !== undefined && layouts[breakpoint][index]['h'] > widget[key]) {
                                layouts[breakpoint][index]['h'] = widget[key]
                            }
                        }
                    })
                }
            })
        }
        console.log('PATCH B', layouts)

        return http.patch("/dashboard/layouts", layouts)
            .then(response => {

                if (response.status === 200) {
                    dispatch(patchDashboardLayoutsSuccess(layouts))
                }
                return true
            }).catch(error => {

                let message = "Fehler beim Speichern des Dashboard Layouts."
                if (error.response && error.response.data.error) {
                    message = error.response.data.error
                }
                dispatch(notificationActions.notify("error", message))
                dispatch(patchDashboardLayoutsFailure())
                return false
            })
    }
}

const patchDashboardLayoutsStart = createAction("dashboard/patchDashboardLayoutsStart")

const patchDashboardLayoutsSuccess = createAction("dashboard/patchDashboardLayoutsSuccess", function prepare(layouts) {
    return {
        payload: {
            layouts
        }
    }
})

const patchDashboardLayoutsFailure = createAction("dashboard/patchDashboardLayoutsFailure")


const deleteWidgetFailure = createAction("dashboard/deleteWidgetFailure")

const reset = createAction('dashboard/reset')

export default {
    reset,
    fetchConfig,
    fetchConfigStart,
    fetchConfigSuccess,
    fetchConfigFailure,
    fetchWidgets,
    fetchWidgetsStart,
    fetchWidgetsSuccess,
    fetchWidgetsFailure,
    addWidget,
    addWidgetStart,
    addWidgetSuccess,
    addWidgetFailure,
    deleteWidget,
    deleteWidgetStart,
    deleteWidgetSuccess,
    deleteWidgetFailure,
    patchDashboard,
    patchDashboardStart,
    patchDashboardSuccess,
    patchDashboardFailure,
    patchDashboardLayouts,
    patchDashboardLayoutsStart,
    patchDashboardLayoutsSuccess,
    patchDashboardLayoutsFailure
}
