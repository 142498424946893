import {colors, createMuiTheme} from '@material-ui/core'

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import {makeStyles} from "@material-ui/styles"

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  globalUseStyles: makeStyles(theme => ({
    checkboxFirstLevel: {
      color: "#e46c0a",
      "&.Mui-checked": {
        color: "#e46c0a"
      }
    },
    checkboxSecondLevel: {
      color: "#FA9502",
      "&.Mui-checked": {
        color: "#FA9502"
      }
    }
  }))
});

export default theme;
