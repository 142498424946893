import {createReducer} from "@reduxjs/toolkit"
import actions from './actions'

const initialState = {
    isLoggedIn: false,
    loading: false,
    token: null,
    userName: '',
    userVorname: '',
    userNachname: '',
    userEmail: '',
    userTelefon: '',
    userMobil: '',
    userStrasse: '',
    userPlz: '',
    userStadt: '',
    profileImage: null,
}

export default createReducer(initialState, {
    [actions.reset]: (state) => {
        state.isLoggedIn = initialState.isLoggedIn
        state.loading = initialState.loading
        state.token = initialState.token
        state.userName = initialState.userName
        state.userVorname = initialState.userVorname
        state.userNachname = initialState.userNachname
        state.userEmail = initialState.userEmail
        state.userTelefon = initialState.userTelefon
        state.userMobil = initialState.userMobil
        state.userStrasse = initialState.userStrasse
        state.userPlz = initialState.userPlz
        state.userStadt = initialState.userStadt
        state.profileImage = initialState.profileImage
    },
    [actions.loginStart]: state => {
        state.loading = true
    },
    [actions.setAuthData]: (state, action) => {
        state.isLoggedIn = true
        state.loading = false
        state.token = action.payload.token
        state.userName = action.payload.authData.vorname + ' ' + action.payload.authData.nachname
        state.userEmail = action.payload.authData.email
        state.userVorname = action.payload.authData.vorname
        state.userNachname = action.payload.authData.nachname
        state.userEmail = action.payload.authData.email
        state.userTelefon = action.payload.authData.telefon
        state.userMobil = action.payload.authData.mobil
        state.userStrasse = action.payload.authData.strasse
        state.userPlz = action.payload.authData.plz
        state.userStadt = action.payload.authData.stadt
        state.profileImage = action.payload.authData.profileImage
    },
    [actions.clearAuthData]: state => {
        state.isLoggedIn = false
        state.loading = false
        state.token = null
        state.userName = ''
        state.userEmail = ''
    },
    [actions.loginFailure]: state => {
        state.loading = false
    },
    [actions.logoutStart]: state => {
        state.loading = true
    },
    [actions.logoutFailure]: state => {
        state.loading = false
    },
    [actions.forgotPasswordStart]: state => {
        state.loading = true
    },
    [actions.forgotPasswordDone]: state => {
        state.loading = false
    },
    [actions.resetPasswordStart]: state => {
        state.loading = true
    },
    [actions.resetPasswordDone]: state => {
        state.loading = false
    },
    [actions.pushAccountDataStart]: state => {
        state.loading = true
    },
    [actions.pushAccountDataDone]: state => {
        state.loading = false
    }
})
