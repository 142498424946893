import {createReducer} from "@reduxjs/toolkit"
import actions from './actions'

const initialState = {
    initialized: false,
    location: {
        last: null,
        current: null
    },
    settings: {
        dataGroupingUnit: 'day',
        date: {
            start: null,
            end: null,
            min: null,
            max: null,
        },
        dateMeasurements: {
            start: null,
            end: null,
            min: null,
            max: null
        },
        groupingMeasurements: false,
        tarife: {},
        messkonzept: null
    }
}

export default createReducer(initialState, {
    [actions.initializeStart]: state => {
        state.initialized = false
    },
    [actions.initializeDone]: state => {
        state.initialized = true
    },
    [actions.updateSettingsDone]: (state, action) => {
        if('dataGroupingUnit' in action.payload.settingsObj) {
            state.settings.dataGroupingUnit = action.payload.settingsObj.dataGroupingUnit
        }

        if('groupingMeasurements' in action.payload.settingsObj) {
            state.settings.groupingMeasurements = action.payload.settingsObj.groupingMeasurements
        }

        if('messkonzept' in action.payload.settingsObj) {
            state.settings.messkonzept = action.payload.settingsObj.messkonzept
        }

        if('tarife' in action.payload.settingsObj) {
            state.settings.tarife = action.payload.settingsObj.tarife
        }

        if('date' in action.payload.settingsObj) {
            state.settings.date = {
                ...state.settings.date,
                ...action.payload.settingsObj.date
            }
        }

        if('dateMeasurements' in action.payload.settingsObj) {
            state.settings.dateMeasurements = {
                ...state.settings.dateMeasurements,
                ...action.payload.settingsObj.dateMeasurements
            }
        }
    },
    [actions.setLocation]: (state, action) => {
        state.location.last = action.payload.lastLocation
        state.location.current = action.payload.currentLocation
    }
})
